import { useEffect, useMemo, useState } from "react";
import { useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { fetchCurrentUser } from "@app/store/currentUser/currentUser.actions";
import {
    getIsOrgHasFeature,
    getUserRedirectPath,
    getUserStudy,
} from "@app/store/currentUser/currentUser.selector";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { StlLightbox, StlNotification } from "@common/components";
import { MEASUREMENT_UNITS } from "@common/constants/measurementUnits.constants";
import { ROUTES } from "@common/constants/routes.constants";
import {
    INITIAL_STUDY_STATE_OBJ,
    STUDY_MODES,
} from "@common/features/studyModal/common/study.constants";
import {
    getIsBPNumberRequired,
    getIsEnterpriseStudy,
    getIsProjectNumberRequired,
    getMaxBufferSize,
    studyDataToState,
    studyStateToData,
} from "@common/features/studyModal/common/study.helpers";
import { IStudy, IStudyData } from "@common/features/studyModal/common/study.types";
import { TValidationSchema, useForm, VALIDATORS } from "@common/hooks/useForm";
import { OrgApiService } from "@common/services/server/orgApi.service";
import { StudiesApiService } from "@common/services/server/studiesApi.service";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ManageStudy } from "./manageStudy/manageStudy";
import "./manageStudyModal.less";

const getTitle = (
    study: IStudyData | null,
    mode: keyof typeof STUDY_MODES,
    isConvertToActiveProject: boolean,
) => {
    const studyName = study?.study_name || "";
    if (mode === STUDY_MODES.VIEW) {
        return `View Study: ${studyName}`;
    } else if (mode === STUDY_MODES.CREATE) {
        return "Create Study";
    }
    return (
        <>
            Update Study: {studyName}
            {isConvertToActiveProject && (
                <>
                    <FontAwesomeIcon icon={faAngleRight} className="separator-icon" />
                    Convert to Project
                </>
            )}
        </>
    );
};

type TState = {
    originalStudy: IStudyData | null;
    study: IStudy;
    showErrors: boolean;
    isConvertToActiveProject: boolean;
};

const INITIAL_STATE: TState = {
    originalStudy: null,
    study: INITIAL_STUDY_STATE_OBJ,
    showErrors: false,
    isConvertToActiveProject: false,
};

const STUDY_VALIDATION_SCHEMA: TValidationSchema<IStudy> = {
    studyType: VALIDATORS.required("Please specify study type"),
    name: [
        (propertyValue, formValue) =>
            VALIDATORS.required("Please specify study name")(propertyValue),
    ],
    endDate: [
        (propertyValue, formValue) =>
            VALIDATORS.required("Please specify study end date")(propertyValue),
    ],
    endClient: [
        (propertyValue, formValue) =>
            getIsEnterpriseStudy(formValue) &&
            VALIDATORS.required("Please specify study end client")(propertyValue),
    ],
    bpCode: [
        (propertyValue, formValue) =>
            getIsBPNumberRequired(formValue) &&
            VALIDATORS.required("Please specify study B&P number")(propertyValue),
    ],
    chargeCode: [
        (propertyValue, formValue) =>
            getIsProjectNumberRequired(formValue) &&
            VALIDATORS.required("Please specify study project number")(propertyValue),
    ],
    buffer: [
        (propertyValue, formValue) => {
            if (!formValue.geometry) return false;

            const unit = formValue.measurementUnit;
            const maxSizeBuffer = getMaxBufferSize(unit);
            const isMiles = unit === MEASUREMENT_UNITS.MILES.id;

            return isNaN(Number(propertyValue)) ||
                propertyValue === "" ||
                Number(propertyValue) < 0 ||
                Number(propertyValue) > maxSizeBuffer
                ? {
                      lessThan: `Please enter buffer size in range from 0 to ${maxSizeBuffer} ${
                          isMiles ? "miles" : "kilometres"
                      }.`,
                  }
                : false;
        },
    ],
    measurementUnit: [
        (propertyValue, formValue) => {
            if (!formValue.geometry) return false;

            return formValue.buffer !== "" && !propertyValue
                ? { required: "Please choose measurement unit" }
                : false;
        },
    ],
};

type TProps = {
    show: boolean;
    onHide: () => void;
    study: IStudyData | null;
    isReadOnlyMode?: boolean;
};

export const ManageStudyModal = ({
    show,
    onHide,
    study: studyToManage,
    isReadOnlyMode = false,
}: TProps) => {
    const [originalStudy, setOriginalStudy] = useState(INITIAL_STATE.originalStudy);
    const [showErrors, setShowErrors] = useState(INITIAL_STATE.showErrors);
    const [isConvertToActiveProject, setIsConvertToActiveProject] = useState(
        INITIAL_STATE.isConvertToActiveProject,
    );

    const isEnterpriseStudy = useAppSelector(state =>
        getIsOrgHasFeature(state, "enterprise_study_workflow"),
    );
    const userStudy = useAppSelector(getUserStudy);
    const { formValue, setFormValue, patchFormValue, validate } = useForm(INITIAL_STATE.study, {
        validationSchema: STUDY_VALIDATION_SCHEMA,
    });
    const store = useStore();
    const dispatch = useAppDispatch();

    const history = useHistory();

    const mode = useMemo(() => {
        if (isReadOnlyMode) return STUDY_MODES.VIEW;
        if (!studyToManage) return STUDY_MODES.CREATE;

        return STUDY_MODES.EDIT;
    }, [isReadOnlyMode, studyToManage]);

    const { isValid, errors } = useMemo(() => {
        if (!showErrors) return { isValid: true, errors: {} };

        return validate();
    }, [showErrors, validate]);

    useEffect(() => {
        if (!show || mode === STUDY_MODES.CREATE || !studyToManage) return;

        StudiesApiService.getStudy(studyToManage.study_id).then(({ study }) => {
            setFormValue(studyDataToState(study));
            setOriginalStudy(study);
        });
    }, [studyToManage, setFormValue, show, mode]);

    useEffect(() => {
        if (!show) {
            setFormValue(INITIAL_STATE.study);
        }
        setShowErrors(INITIAL_STATE.showErrors);
        setIsConvertToActiveProject(INITIAL_STATE.isConvertToActiveProject);
    }, [setFormValue, setShowErrors, show]);

    useEffect(() => {
        if (mode === STUDY_MODES.CREATE && (!formValue.startDate || !formValue.endDate)) {
            const startDate = moment().valueOf();
            const studyDaysLength = isEnterpriseStudy ? 365 : 90;
            const endDate = moment(startDate).add(studyDaysLength, "days").valueOf();

            patchFormValue({
                startDate,
                endDate,
            });
        }
    }, [mode, isEnterpriseStudy, formValue, patchFormValue]);

    const handleError = (error: any) => {
        const errorMsg = error.response.data?.message;

        if (errorMsg) {
            return StlNotification.error(errorMsg);
        }

        return Promise.reject(error);
    };

    const saveStudy = () => {
        const { isValid: isValidData, errors: validationErrors } = validate();

        if (!isValidData) {
            // Collect all validation errors in one string
            const errorTexts = Object.values(validationErrors)
                .reduce((res: Array<string>, validationResult) => {
                    const validationTexts = Object.values(validationResult);
                    return [...res, ...validationTexts];
                }, [])
                .join(". ");

            setShowErrors(true);
            StlNotification.error(errorTexts);

            return Promise.reject({ handled: true });
        }

        const serializedStudy = studyStateToData(formValue, isEnterpriseStudy);

        if (mode === STUDY_MODES.CREATE) {
            return StudiesApiService.createStudy(serializedStudy)
                .then(res => {
                    const params = { study_id: res.study.study_id };

                    return OrgApiService.setStudy(params).then(() => {
                        dispatch(fetchCurrentUser()).then(() => {
                            history.push(getUserRedirectPath(store.getState()));
                        });
                        return Promise.resolve();
                    });
                })
                .then(onHide)
                .catch(handleError);
        } else {
            return StudiesApiService.updateStudy(formValue.studyId, serializedStudy)
                .then(() => {
                    //Refresh study data for currentUser
                    if (formValue.studyId === userStudy.study_id) {
                        dispatch(fetchCurrentUser());
                    }
                    onHide();
                })
                .catch(handleError);
        }
    };

    return (
        <StlLightbox
            show={show}
            className="stl-manage-study-modal"
            title={getTitle(studyToManage, mode, isConvertToActiveProject)}
            body={
                <ManageStudy
                    study={formValue}
                    originalStudy={originalStudy}
                    updateStudy={patchFormValue}
                    saveStudy={saveStudy}
                    closeModal={onHide}
                    mode={mode}
                    errors={errors}
                    isValid={isValid}
                    isEnterpriseStudy={isEnterpriseStudy}
                    isConvertToActiveProject={isConvertToActiveProject}
                    setIsConvertToActiveProject={setIsConvertToActiveProject}
                />
            }
            onHide={onHide}
            centered
        />
    );
};
