import {
    AVAILABLE_AADT_YEARS,
    AVAILABLE_ALL_VEHICLES_BY_WEIGHT_YEARS,
    CALIBRATIONS,
    CALIBRATIONS_LIST,
    HISTORICAL_DATA_YEARS,
} from "@app/analysis/state/analysisConfiguration.constants";
import { parseDateRange } from "@app/analysis/timePeriods/state/timePeriods.helpers";
import {
    getOsmLayersCategories,
    getPreferredCountry,
    getProjectFoldersPreferences,
} from "@app/store/userPreferences/userPreferences.selector";
import { OSM_LAYERS_LIST } from "@common/components/baseMap/mapLayers/mapLayers.constants";
import { MODES_OF_TRAVEL, ORG_COUNTRIES } from "@common/constants/analysis.constants";
import { ORG_TYPES, PLATFORM_ACCESS_TYPES } from "@common/constants/orgTypes.constants";
import { ROUTES } from "@common/constants/routes.constants";
import { createSelector } from "reselect";

import { USER_ROLES } from "./currentUser.constants";
import { getUserPreferredCountry } from "./currentUser.helpers";

const _orgHasFeature = (isSuperUser, features, featureName, options = {}) => {
    if (!options.withoutSuperUser && isSuperUser) return true;

    if (!featureName || !features) return false;

    return !!features.find(feature => feature.code_name === featureName && feature.enabled);
};

const _userHasRole = (isSuperUser, roles, roleName, options = {}) => {
    if (!options.withoutSuperUser && isSuperUser) return true;

    if (!roleName || !roles) return false;

    return !!roles.find(role => role === roleName);
};

const _userHasPrivilege = (isSuperUser, privileges, privilegeName) => {
    if (isSuperUser) return true;

    if (!privilegeName || !privileges) return false;

    return privileges.includes(privilegeName);
};

export const getCurrentUser = state => state.currentUser;
export const getUserInfo = state => state.currentUser.user;
export const getUserOrg = state => state.currentUser.user.org;
export const getUserStudy = state => state.currentUser.user.study;
export const getConfigParams = state => state.currentUser.configParams;
export const getUserRoles = state => state.currentUser.user?.roles;

export const getIsSandbox = state => getUserOrg(state)?.org_type === ORG_TYPES.SANDBOX.id;
export const getCanAccessSandbox = state => getCurrentUser(state).canAccessSandbox;
export const getIsSuperUser = state => getCurrentUser(state).isSuper;
export const getSubscription = state => getUserInfo(state).subscription || {};
export const getSubscriptionMetricsPackages = state =>
    getSubscription(state).metrics_packages || [];
export const getIsAdmin = state => getUserInfo(state).is_admin;

const getOrgFeatures = state => getCurrentUser(state).user.subscription?.features;
const getUserPrivileges = state => getCurrentUser(state).user.privs;

export const getIsOrgHasFeature = (state, feature, options) => {
    const isSuper = getIsSuperUser(state);
    const features = getOrgFeatures(state);

    return _orgHasFeature(isSuper, features, feature, options);
};

export const getIsUserHasRole = (state, specificRole, options) => {
    const isSuper = getIsSuperUser(state);
    const userRoles = getUserRoles(state);

    return _userHasRole(isSuper, userRoles, specificRole, options);
};

export const getIsUserHasPrivilege = (state, privilege) => {
    const isSuper = getIsSuperUser(state);
    const privileges = getUserPrivileges(state);

    return _userHasPrivilege(isSuper, privileges, privilege);
};

export const isGenerateVisualizationAvailable = state => {
    return getIsOrgHasFeature(state, "advance_viz") && getIsOrgHasFeature(state, "generate_viz");
};

export const isDownLoadAvailable = state => {
    return getIsOrgHasFeature(state, "download_metrics");
};

export const getIsAugmentedDataAvailable = state => getIsOrgHasFeature(state, "augmented_data");

export const isShowExportUniqueZoneInfo = createSelector(
    getCurrentUser,
    getUserOrg,
    state => getIsOrgHasFeature(state, "display_unique_zone_count"),
    (currentUser, userOrgBalance, isDisplay) =>
        isDisplay &&
        userOrgBalance.is_study_enabled &&
        currentUser.user.study.unique_zone_count !== null,
);

export const getIsVizScreenshotsAvailable = createSelector(
    getIsSuperUser,
    getIsSandbox,
    state =>
        getIsUserHasRole(state, USER_ROLES.FULL_ACCESS) ||
        getIsUserHasRole(state, USER_ROLES.VIEW_AND_DOWNLOAD_ONLY),
    state => getIsOrgHasFeature(state, "download_viz_data"),
    (isSuperUser, isSandbox, userHasRole, orgHasFeature) => {
        if (isSandbox) return false;
        if (isSuperUser) return true;

        return userHasRole && orgHasFeature;
    },
);

export const getIsVizCsvExportAvailable = createSelector(
    state =>
        getIsUserHasRole(state, USER_ROLES.FULL_ACCESS) ||
        getIsUserHasRole(state, USER_ROLES.VIEW_AND_DOWNLOAD_ONLY),
    state => getIsOrgHasFeature(state, "download_viz_data"),
    (userHasRole, orgHasFeature) => userHasRole && orgHasFeature,
);

export const getUniqueZonesRemainCount = createSelector(
    getCurrentUser,
    currentUser => currentUser.orgBalance.uniqueZoneRemain,
);

export const getUniqueZonesQuota = createSelector(
    getCurrentUser,
    currentUser => currentUser.orgBalance.uniqueZoneQuota,
);

export const getStudyUniqueZoneCount = createSelector(
    getCurrentUser,
    currentUser => currentUser.orgBalance.studyUniqueZoneCount,
);

export const getDaysRemainCount = createSelector(
    getCurrentUser,
    currentUser => currentUser.orgBalance.daysRemain,
);

export const getAnalysesRemainCount = createSelector(
    getCurrentUser,
    currentUser => currentUser.orgBalance.analysesRemain,
);

export const getZonesPerAnalysisCount = createSelector(
    getCurrentUser,
    currentUser => currentUser.orgBalance.zonesPerAnalysis,
);

export const getUniqueZoneCount = createSelector(
    getCurrentUser,
    currentUser => currentUser.orgBalance.uniqueZoneCount,
);

export const getRemainingZoneCount = createSelector(
    getUniqueZonesQuota,
    getUniqueZoneCount,
    (uniqueZoneQuota, uniqueZoneCount) => {
        if (typeof uniqueZoneQuota !== "number" || typeof uniqueZoneCount !== "number") {
            return null;
        }

        return uniqueZoneQuota - uniqueZoneCount;
    },
);

export const getIsEmulating = state => {
    const { user, realUser } = getCurrentUser(state);

    return user.user_id !== realUser.user_id;
};

export const getIsStudyReadOnly = state => {
    const userStudy = getUserStudy(state);

    if (!userStudy) return undefined;

    return userStudy.status === "Read_Only" || !userStudy.is_active;
};

export const getUserCountry = state => {
    const org = getUserOrg(state);
    const preferredCountryCode = getPreferredCountry(state);

    return getUserPreferredCountry({
        org,
        preferredCountryCode,
        defaultCountry: ORG_COUNTRIES.US,
    });
};

export const getAvailableAADTYears = state => {
    const {
        staticData: { availableDataPeriods },
        analysisConfiguration: {
            basics: { travelModeCode },
        },
    } = state;
    const isSandbox = getIsSandbox(state);
    const { code: countryCode } = getUserCountry(state);

    const allAvailableYears = availableDataPeriods?.length
        ? availableDataPeriods.map(({ year }) => year)
        : AVAILABLE_AADT_YEARS;
    const isAllVehiclesByWeightMode =
        travelModeCode === MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code;
    const availableAADTYears = isAllVehiclesByWeightMode
        ? AVAILABLE_ALL_VEHICLES_BY_WEIGHT_YEARS
        : AVAILABLE_AADT_YEARS;

    return availableAADTYears.filter(year => {
        //AADT 2021/2023 are not supported for Canada
        if (countryCode === ORG_COUNTRIES.CA.code && [2021, 2022, 2023].includes(year)) {
            return false;
        }
        return (
            (!isSandbox || allAvailableYears.includes(year)) &&
            getIsOrgHasFeature(state, `estimated_${year}_aadt`) &&
            getIsOrgHasFeature(state, `data_period_${year}`)
        );
    });
};

export const getAvailableAADTCalibrationYears = state => {
    const availableAADTYears = getAvailableAADTYears(state);
    // INST-31487: AADT 2023 should not be available for calibration in other analysis types
    return availableAADTYears.filter(year => year !== 2023);
};

export const getProducts = state => {
    const { products } = getCurrentUser(state);
    return products;
};

export const getAvailableCalibrations = state => {
    return CALIBRATIONS_LIST.filter(calibration => {
        if (calibration.code === CALIBRATIONS.AADT.code) {
            return (
                (getIsOrgHasFeature(state, "estimated_2016_aadt") &&
                    getIsOrgHasFeature(state, "data_period_2016")) ||
                (getIsOrgHasFeature(state, "estimated_2017_aadt") &&
                    getIsOrgHasFeature(state, "data_period_2017")) ||
                (getIsOrgHasFeature(state, "estimated_2018_aadt") &&
                    getIsOrgHasFeature(state, "data_period_2018")) ||
                (getIsOrgHasFeature(state, "estimated_2019_aadt") &&
                    getIsOrgHasFeature(state, "data_period_2019")) ||
                (getIsOrgHasFeature(state, "estimated_2020_aadt") &&
                    getIsOrgHasFeature(state, "data_period_2020")) ||
                (getIsOrgHasFeature(state, "estimated_2021_aadt") &&
                    getIsOrgHasFeature(state, "data_period_2021")) ||
                (getIsOrgHasFeature(state, "estimated_2022_aadt") &&
                    getIsOrgHasFeature(state, "data_period_2022"))
            );
        } else {
            return getIsOrgHasFeature(state, calibration.featureName);
        }
    });
};

export const getAvailableOSMLayers = createSelector(
    getIsSuperUser,
    getOrgFeatures,
    (isSuperUser, features) => {
        return OSM_LAYERS_LIST.filter(layer =>
            layer.features.some(flag => _orgHasFeature(isSuperUser, features, flag)),
        );
    },
);

export const getSelectedOsmLayersCategories = createSelector(
    getOsmLayersCategories,
    getAvailableOSMLayers,
    (osmLayersCategories, availableLayers) => {
        return availableLayers.reduce((res, layer) => {
            const osmLayersCategoryChildren = osmLayersCategories[layer.code];

            if (osmLayersCategoryChildren?.length) {
                res.push({
                    ...layer,
                    children: osmLayersCategoryChildren,
                });
            }

            return res;
        }, []);
    },
);

export const getHasAccessToInSight = state => {
    const { user, isSuper } = getCurrentUser(state);
    const isAdmin = user.is_admin;
    const productAccessTypes = user.org?.product_access_types || [];

    if (isSuper || isAdmin) return true;

    return (
        productAccessTypes.includes(PLATFORM_ACCESS_TYPES.INSIGHT.value) ||
        productAccessTypes.includes(PLATFORM_ACCESS_TYPES.INSIGHT_WITH_LITE.value)
    );
};

export const getHasAccessToInSightGo = state => {
    const { user, isSuper } = getCurrentUser(state);
    const isAdmin = user.is_admin;
    const productAccessTypes = user.org?.product_access_types || [];
    const hasInSightGoRole = user.roles?.includes("InSight Go");

    if (isSuper || isAdmin) return true;

    return (
        productAccessTypes.includes(PLATFORM_ACCESS_TYPES.LITE.value) ||
        (productAccessTypes.includes(PLATFORM_ACCESS_TYPES.INSIGHT_WITH_LITE.value) &&
            hasInSightGoRole)
    );
};

export const getHasAccessToInSightSafety = state => {
    const { user, isSuper } = getCurrentUser(state);

    if (isSuper || user.is_admin) return true;
    return !!getSubscription(state).safety_solution_url;
};

/**
 * Documented at https://streetlightdata.atlassian.net/wiki/spaces/C/pages/586186787
 */
export const getRoutePermissions = createSelector(
    getCurrentUser,
    getOrgFeatures,
    getUserPrivileges,
    getHasAccessToInSightGo,
    getHasAccessToInSightSafety,
    (currentUser, features, privileges, hasAccessToInSightGo, hasAccessToInSightSafety) => {
        const products = currentUser.products;
        const hasInsight = products?.some(
            product => product.product_type === PLATFORM_ACCESS_TYPES.INSIGHT.value,
        );
        const hasPrioritize = products?.some(
            product => product.product_type === PLATFORM_ACCESS_TYPES.PRIORITIZE.value,
        );

        if (!hasInsight) {
            return hasPrioritize ? { [ROUTES.SAFETY]: hasAccessToInSightSafety } : {};
        }
        const isSuper = currentUser.isSuper;
        const hasPrivilege = privilegeName =>
            _userHasPrivilege(isSuper, privileges, privilegeName);
        const hasFeature = (featureName, options) =>
            _orgHasFeature(isSuper, features, featureName, options);
        const hasInsightAccess = (routeAccess = true) => {
            const _hasInsightAccess = !!currentUser.user.org?.product_access_types?.some(type =>
                [
                    PLATFORM_ACCESS_TYPES.INSIGHT.value,
                    PLATFORM_ACCESS_TYPES.INSIGHT_WITH_LITE.value,
                ].includes(type),
            );
            return (isSuper || _hasInsightAccess) && routeAccess;
        };

        const getHasAnalysesAccess = () => {
            const nonAdminRoles = currentUser.user.roles?.filter(
                roleName => roleName !== "Admin Dashboard",
            );
            const hasAdminDashboardPrivilege = hasPrivilege("admin dashboard");
            const dashboardAdminWithProjectPrivilege =
                hasAdminDashboardPrivilege && nonAdminRoles.length > 0;

            return (
                currentUser.user.org?.org_type !== "parent_organization" &&
                (!hasAdminDashboardPrivilege || dashboardAdminWithProjectPrivilege)
            );
        };
        const hasAnalysesAccess = hasInsightAccess(getHasAnalysesAccess());

        const getHasViz3Access = () => {
            return hasFeature("enable_viz3") && hasPrivilege("visualize travel metrics");
        };
        const hasViz3Access = hasInsightAccess(getHasViz3Access());

        const hasCustomerAdminAccess =
            hasPrivilege("admin dashboard") && hasFeature("admin_dashboard");
        const hasDashboardAccess = hasInsightAccess(isSuper || hasCustomerAdminAccess);

        const hasProjectFoldersAccess =
            hasPrivilege("project viewer") &&
            hasFeature("enable_project_folders", {
                withoutSuperUser: true,
            });
        return {
            [ROUTES.PROJECTS]: hasProjectFoldersAccess,
            [ROUTES.PROJECT_FOLDER]: hasProjectFoldersAccess,
            [ROUTES.ZONES]: hasAnalysesAccess && hasPrivilege("manage zone"),
            [ROUTES.LIGHTNING_ANALYSIS]: hasAnalysesAccess,
            [ROUTES.ANALYSES]:
                hasAnalysesAccess &&
                (hasPrivilege("visualize travel metrics") ||
                    hasPrivilege("download travel metrics")),
            [ROUTES.VIZ3]: hasViz3Access,
            [ROUTES.STUDIES]: hasInsightAccess(currentUser.user.org?.is_study_enabled),
            [ROUTES.DASHBOARD]: hasDashboardAccess,
            [ROUTES.DASHBOARD_SUMMARY]: hasDashboardAccess,
            [ROUTES.DASHBOARD_ORGS]: hasDashboardAccess,
            [ROUTES.DASHBOARD_SUBSCRIPTIONS]: hasDashboardAccess,
            [ROUTES.DASHBOARD_STUDIES]: hasDashboardAccess,
            [ROUTES.DASHBOARD_ANALYSES]: hasDashboardAccess,
            [ROUTES.ESRI_EXPORT]: true,
            [ROUTES.DASHBOARD_USERS]: hasDashboardAccess,
            [ROUTES.ADMIN]: isSuper,
            [ROUTES.ADMIN_USERS]: isSuper,
            [ROUTES.ADMIN_STUDIES]: isSuper,
            [ROUTES.ADMIN_ORGS]: isSuper,
            [ROUTES.ADMIN_PENDING_ANALYSES]: isSuper,
            [ROUTES.ADMIN_REVIEW_ANALYSES]: isSuper,
            [ROUTES.ADMIN_AUTO_PASSED_ZONES]: isSuper,
            [ROUTES.ADMIN_ABOUT]: isSuper,
            [ROUTES.PIRATE_SHIP]: hasAccessToInSightGo,
            [ROUTES.PIRATE_SHIP_ANALYZE]: hasAccessToInSightGo,
            [ROUTES.PIRATE_SHIP_RESULTS]: hasAccessToInSightGo,
            [ROUTES.PIRATE_SHIP_SHARED_LINK]: hasAccessToInSightGo,
            [ROUTES.SAFETY]: hasAccessToInSightSafety,
        };
    },
);

export const getHasAccessToViz3 = createSelector(
    getRoutePermissions,
    routePermissions => routePermissions[ROUTES.VIZ3],
);

export const getUserRedirectPath = createSelector(
    getUserInfo,
    getRoutePermissions,
    getProjectFoldersPreferences,
    getCurrentUser,
    (userInfo, routePermissions, { projectFolderId }, { products }) => {
        const nonInsightProductUrl = products?.reduce((url, product) => {
            if (
                product.product_type !== PLATFORM_ACCESS_TYPES.INSIGHT.value &&
                !product.subscriptions_expired
            ) {
                return url ? null : product.product_url;
            }
            return url;
        }, null);

        if (nonInsightProductUrl) {
            return nonInsightProductUrl;
        }

        if (!userInfo.org) {
            return "/chooseOrg";
        } else if (userInfo.org.is_study_enabled && !userInfo.study) {
            return "/chooseStudy";
        }

        if (routePermissions[ROUTES.ADMIN]) {
            return ROUTES.ADMIN;
        } else if (routePermissions[ROUTES.PROJECTS]) {
            //TODO: Handle case if project folder ID is saved in user preferences, but user doesn't have access to this project
            return Number(projectFolderId)
                ? `${ROUTES.PROJECTS}/${projectFolderId}`
                : ROUTES.PROJECTS;
        } else if (routePermissions[ROUTES.ANALYSES]) {
            return ROUTES.ANALYSES;
        } else if (routePermissions[ROUTES.ZONES]) {
            return ROUTES.ZONES;
        } else if (routePermissions[ROUTES.PIRATE_SHIP]) {
            return ROUTES.PIRATE_SHIP;
        } else if (routePermissions[ROUTES.STUDIES]) {
            return ROUTES.STUDIES;
        } else if (routePermissions[ROUTES.SAFETY]) {
            const hasSafetyOnlyAccess =
                userInfo.org.product_access_types.length === 1 &&
                userInfo.org.product_access_types.includes(PLATFORM_ACCESS_TYPES.PRIORITIZE.value);
            return hasSafetyOnlyAccess ? ROUTES.SAFETY : ROUTES.DEFAULT;
        } else {
            //TODO: add default route
            return ROUTES.DEFAULT;
        }
    },
);

export const getByPassSignatureDiff = createSelector(getConfigParams, configParams => {
    // application is run with vite dev server
    if (IS_DEVELOPMENT) return true;

    // check whether it's local webapp server
    return configParams.is_development !== undefined ? configParams.is_development : true;
});

export const getSubscriptionRegion = createSelector(getUserInfo, userInfo => {
    const studyRegion = userInfo.study?.region;
    if (studyRegion) {
        return studyRegion;
    }

    return userInfo.subscription?.region || null;
});

export const getSubscriptionAndStudyIds = createSelector(
    getUserInfo,
    ({ study, subscription }) => ({
        studyRegionId: study?.region?.id ?? null,
        subscriptionRegionId: subscription?.region?.id ?? null,
    }),
);

export const getAvailableDataYears = createSelector(
    getIsSuperUser,
    getOrgFeatures,
    (isSuper, features) => {
        return HISTORICAL_DATA_YEARS.filter(year => {
            return _orgHasFeature(isSuper, features, `data_period_${year}`);
        });
    },
);

export const getPartialDataPeriods = createSelector(
    getConfigParams,
    getAvailableDataYears,
    (configParams, availableDataYears) => {
        const partialPeriods = configParams?.partial_data_periods || [];

        return partialPeriods
            .filter(period => availableDataYears.some(year => period.includes(year)))
            .map(parseDateRange);
    },
);

export const getPlatformAccess = state => {
    const { isSuper, user } = getCurrentUser(state);
    const { product_access_types } = user.org;

    if (isSuper) return PLATFORM_ACCESS_TYPES.INSIGHT_WITH_LITE.value;

    return product_access_types;
};

export const getOrgHasMultipleCountriesAccess = state => {
    const org = getUserOrg(state);
    return org.country_ca && org.country_us;
};

export const getOrgCountriesAccess = state => {
    const org = getUserOrg(state);

    if (org.country_ca && org.country_us) return [ORG_COUNTRIES.US.code, ORG_COUNTRIES.CA.code];

    return [org.country_us ? ORG_COUNTRIES.US.code : ORG_COUNTRIES.CA.code];
};
