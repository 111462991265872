import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GENERAL_INITIAL_STATE, IGeneralState } from "./general.state";

export const { actions, reducer } = createSlice({
    name: "general",
    initialState: GENERAL_INITIAL_STATE,
    reducers: {
        resetState: () => {
            return GENERAL_INITIAL_STATE;
        },
        updateState: (state, action: PayloadAction<Partial<IGeneralState>>) => {
            return { ...state, ...action.payload };
        },
        setGeneralInitialData: (state, action: PayloadAction<Partial<IGeneralState>>) => {
            return {
                ...state,
                ...action.payload,
                uiStates: {
                    ...state.uiStates,
                    ...action.payload.uiStates,
                },
            };
        },
        setAnalysisTypeCode: (state, action: PayloadAction<IGeneralState["analysisTypeCode"]>) => {
            state.analysisTypeCode = action.payload;
        },
        updateAnalysisVersionId: (
            state,
            action: PayloadAction<IGeneralState["analysisVersionId"]>,
        ) => {
            state.analysisVersionId = action.payload;
        },
        showPreviewAnalysis: (
            state,
            action: PayloadAction<IGeneralState["uiStates"]["showPreviewAnalysis"]>,
        ) => {
            state.uiStates.showPreviewAnalysis = action.payload;
        },
        setIs15MinuteBinsActive: (
            state,
            action: PayloadAction<IGeneralState["uiStates"]["is15MinuteBinsModeActive"]>,
        ) => {
            state.uiStates.is15MinuteBinsModeActive = action.payload;
        },
        setZonesMode: (state, action: PayloadAction<IGeneralState["uiStates"]["zonesMode"]>) => {
            state.uiStates.zonesMode = action.payload;
        },
        setScreenMode: (state, action: PayloadAction<IGeneralState["uiStates"]["screenMode"]>) => {
            state.uiStates.screenMode = action.payload;
        },
        setActiveTab: (state, action: PayloadAction<IGeneralState["uiStates"]["activeTab"]>) => {
            state.uiStates.activeTab = action.payload;
        },
        setIsAnalysisTypeChanged: (
            state,
            action: PayloadAction<IGeneralState["uiStates"]["isAnalysisTypeChanged"]>,
        ) => {
            state.uiStates.isAnalysisTypeChanged = action.payload;
        },
        setIsShowAllAnalysesAllowed: (
            state,
            action: PayloadAction<IGeneralState["uiStates"]["isShowAllAnalysesAllowed"]>,
        ) => {
            state.uiStates.isShowAllAnalysesAllowed = action.payload;
        },
        showAnalysisSizeReviewModal: (
            state,
            action: PayloadAction<IGeneralState["runAnalysis"]["sizeReviewData"]>,
        ) => {
            state.runAnalysis.sizeReviewData = action.payload;
        },
        resetAnalysisSizeReviewData: state => {
            state.runAnalysis.sizeReviewData = null;
        },
        showZoneQuotaModal: (
            state,
            action: PayloadAction<IGeneralState["runAnalysis"]["uniqueZoneData"]>,
        ) => {
            state.runAnalysis.uniqueZoneData = action.payload;
        },
        resetUniqueZoneData: state => {
            state.runAnalysis.uniqueZoneData = null;
        },
        saveAnalysisSuccess: (
            state,
            action: PayloadAction<IGeneralState["runAnalysis"]["isDraft"]>,
        ) => {
            state.runAnalysis = {
                status: "ok",
                submitted: true,
                isDraft: action.payload,
            };
        },
        saveAnalysisReset: state => {
            // status and error are not reset so that UI is not accidentally updated.
            // This strategy avoids the complexity of having separate actions to
            // reset submit status and to clear status.
            state.runAnalysis.submitted = false;
        },
        setAnalysisActionError: (
            state,
            action: PayloadAction<IGeneralState["runAnalysis"]["error"]>,
        ) => {
            state.runAnalysis = {
                submitted: true,
                status: "error",
                error: action.payload,
            };
        },
        setAnalysisValidation: (
            state,
            action: PayloadAction<IGeneralState["validation"]["isInvalid"]>,
        ) => {
            state.validation.isInvalid = action.payload;
        },
        setGeneralUiState: (state, action: PayloadAction<Partial<IGeneralState["uiStates"]>>) => {
            state.uiStates = { ...state.uiStates, ...action.payload };
        },
        setProjectFolder: (state, action: PayloadAction<IGeneralState["projectFolder"]>) => {
            state.projectFolder = action.payload;
        },
    },
});

export const generalReducer = reducer;
