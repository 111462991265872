import { useCallback, useEffect, useMemo, useState } from "react";
import type { FeatureCollection } from "geojson";
import { debounce } from "lodash-es";
import { FillLayer, LineLayer, LngLatBounds, Map } from "mapbox-gl";
import { StlNotification } from "@common/components";
import {
    MIN_INTERACTIVE_ZOOM,
    TMapLayersLocalState,
} from "@common/components/baseMap/mapLayers/mapLayers.constants";
import { COLORS } from "@common/components/select/select";
import {
    ENHANCED_LAYERS,
    ENHANCED_LAYERS_LIST,
} from "@common/features/mapEnhancedLayers/state/mapEnhancedLayers.constants";
import {
    getFEMALayerExpression,
    getFEMALayerStyleConfig,
} from "@common/features/mapEnhancedLayers/state/mapEnhancedLayers.helpers";
import { ArcgisFeatureService } from "@common/services/arcgisFeature.service";
import { getFirstLabelLayerId, removeSource } from "@common/utils/mapUtils";

const recordCount = 2000;

const SOURCE_ID = "stl:fema-source";
const LAYER_ID = "stl:fema-geom-layer";
const OUTLINE_LAYER_ID = "stl:fema-geom-outline-layer";

export const ZOOM_STEPS = {
    MIN: 7.5,
    MIDDLE: 10,
};

const getTolerance = (zoom: number) => {
    if (zoom < ZOOM_STEPS.MIN) return 0.02;

    if (zoom < ZOOM_STEPS.MIDDLE) return 0.01;

    return 0;
};

const getBeforeLayerId = (map: Map) => {
    const { layers = [] } = map.getStyle();

    const firstStlLayerId = layers.find(layer => layer.id.includes("stl:"))?.id;

    return firstStlLayerId || getFirstLabelLayerId(map);
};

export const useFEMAZoneLayers = ({
    map,
    activeLayer,
    selectedZone,
    where = "",
    sourceIdPostfix = "",
    hoveredLegendSwatch,
}: {
    map: mapboxgl.Map | null;
    activeLayer: TMapLayersLocalState["enhancedLayersCategories"];
    selectedZone?: any;
    where?: string;
    sourceIdPostfix?: string;
    hoveredLegendSwatch?: string;
}) => {
    const [bounds, setBounds] = useState<null | LngLatBounds>(null);
    const [staticLayers, setStaticLayers] = useState<LineLayer[]>([]);
    const [interactiveLayers, setInteractiveLayers] = useState<FillLayer[]>([]);

    const sourceId = SOURCE_ID + sourceIdPostfix;

    const activeFEMALayer = useMemo(() => {
        if (!activeLayer.enabled || activeLayer.category === ENHANCED_LAYERS.DAC_ZONES.code)
            return null;

        const category = ENHANCED_LAYERS_LIST.find(layer => layer.code === activeLayer.category);
        if (!category) return null;

        const femaLayer = category.subLayers.find(
            layer =>
                layer.id ===
                activeLayer[
                    category.preferenceProperty as keyof TMapLayersLocalState["enhancedLayersCategories"]
                ],
        );

        return femaLayer
            ? {
                  ...femaLayer,
                  featureIdProperty: category.featureIdProperty,
                  style: category.style,
              }
            : null;
    }, [activeLayer]);

    useEffect(() => {
        if (!map || map.getSource(sourceId) || !activeFEMALayer) return undefined;

        const { featureIdProperty, style, visField } = activeFEMALayer;
        const config = getFEMALayerStyleConfig(visField);
        const labelId = getBeforeLayerId(map);

        map.addSource(sourceId, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
            promoteId: featureIdProperty,
        });

        const outlineLayer: LineLayer = {
            id: OUTLINE_LAYER_ID + sourceIdPostfix,
            type: "line",
            minzoom: MIN_INTERACTIVE_ZOOM.FEMA_ZONES,
            source: sourceId,
            paint: {
                "line-width": [
                    "step",
                    ["zoom"],
                    style.lineWidth / 3,
                    ZOOM_STEPS.MIN,
                    style.lineWidth / 2,
                    ZOOM_STEPS.MIDDLE,
                    style.lineWidth,
                ],
                "line-color": style.color,
            },
        };
        map.addLayer(outlineLayer, labelId);
        setStaticLayers([outlineLayer]);

        const layer: FillLayer = {
            id: LAYER_ID + sourceIdPostfix,
            type: "fill",
            minzoom: MIN_INTERACTIVE_ZOOM.FEMA_ZONES,
            source: sourceId,
            paint: {
                "fill-color": config?.color,
                "fill-opacity": config?.fillOpacity,
            },
            filter: config?.filter,
        };
        map.addLayer(layer, labelId);
        setInteractiveLayers([layer]);

        return () => {
            if (map.getSource(sourceId)) {
                removeSource(map, sourceId);
            }
        };
    }, [map, activeFEMALayer, sourceId, sourceIdPostfix]);

    useEffect(() => {
        if (
            !map ||
            !activeFEMALayer ||
            !interactiveLayers.length ||
            !map.getSource(interactiveLayers[0].source as string)
        )
            return;

        const allLayers = [...interactiveLayers, ...staticLayers];
        const { featureIdProperty, style, visField } = activeFEMALayer;
        const config = getFEMALayerStyleConfig(visField);
        const zoneId = selectedZone?.[featureIdProperty] || "";

        allLayers.forEach(layer => {
            if (layer.id.startsWith(LAYER_ID)) {
                map.setPaintProperty(layer.id, "fill-color", [
                    "case",
                    ["boolean", ["feature-state", "hover"], false],
                    style.hoverColor,
                    ["==", ["get", featureIdProperty], zoneId],
                    style.selectColor,
                    style.color,
                ]);
                map.setPaintProperty(layer.id, "fill-opacity", [
                    "case",
                    ["boolean", ["feature-state", "hover"], false],
                    style.fillOpacity,
                    ["==", ["get", featureIdProperty], zoneId],
                    style.fillOpacity,
                    config.fillOpacity,
                ]);
            } else if (layer.id.startsWith(OUTLINE_LAYER_ID)) {
                map.setPaintProperty(layer.id, "line-color", [
                    "case",
                    ["boolean", ["feature-state", "hover"], false],
                    style.hoverColor,
                    ["==", ["get", featureIdProperty], zoneId],
                    style.selectLineColor,
                    style.color,
                ]);
            }
        });
    }, [map, interactiveLayers, staticLayers, activeFEMALayer, selectedZone]);

    useEffect(() => {
        if (!map || !activeFEMALayer) return;

        const { visField, style } = activeFEMALayer;
        const config = getFEMALayerStyleConfig(visField);
        if (hoveredLegendSwatch) {
            map.setPaintProperty(LAYER_ID + sourceIdPostfix, "fill-color", [
                "case",
                getFEMALayerExpression(visField, hoveredLegendSwatch),
                "rgba(255, 255, 255, 0.80)",
                config?.color,
            ]);
        } else {
            map.setPaintProperty(LAYER_ID + sourceIdPostfix, "fill-color", config?.color);
        }

        // Update line-color
        if (hoveredLegendSwatch) {
            map.setPaintProperty(OUTLINE_LAYER_ID + sourceIdPostfix, "line-color", [
                "case",
                getFEMALayerExpression(visField, hoveredLegendSwatch),
                COLORS.WHITE,
                style?.color,
            ]);
        } else {
            map.setPaintProperty(OUTLINE_LAYER_ID + sourceIdPostfix, "line-color", config?.color);
        }
    }, [map, hoveredLegendSwatch, activeFEMALayer, sourceIdPostfix]);

    const addZonesToMap = useCallback(
        ({
            _map,
            geometry,
            resultOffset,
            signal,
            tolerance,
            features,
            requiredFields,
        }: {
            _map: Map;
            geometry: string;
            resultOffset: number;
            signal: AbortSignal;
            tolerance: number;
            features: FeatureCollection["features"];
            requiredFields: Array<string>;
        }) => {
            ArcgisFeatureService.getPaginatedFEMAZonesLayer(
                {
                    where,
                    geometry,
                    resultOffset,
                    resultRecordCount: recordCount,
                    quantizationParameters: {
                        tolerance,
                    },
                },
                requiredFields,
                signal,
            )
                .then(result => {
                    const source = _map.getSource(sourceId);

                    if (source.type !== "geojson") return;

                    const { properties, ...featureCollection } = result;

                    const updatedFeatureList = [...features, ...featureCollection.features];

                    if (properties?.exceededTransferLimit) {
                        addZonesToMap({
                            _map,
                            geometry,
                            resultOffset: resultOffset + recordCount,
                            signal,
                            tolerance,
                            features: updatedFeatureList,
                            requiredFields,
                        });
                    } else {
                        source.setData({
                            type: "FeatureCollection",
                            features: updatedFeatureList,
                        });
                    }
                })
                .catch(error => {
                    if (error) {
                        StlNotification.error(error);
                    }
                });
        },
        [sourceId, where],
    );

    // Subscribe on bound change
    useEffect(() => {
        if (!map || !activeFEMALayer) return undefined;

        const updateBounds = debounce(() => {
            const _bounds = map.getBounds();

            setBounds(_bounds);
        }, 300);

        map.on("moveend", updateBounds);

        return () => {
            map.off("moveend", updateBounds);
        };
    }, [map, activeFEMALayer]);

    // Set bounds on DAC layer checkbox select
    useEffect(() => {
        if (!map || !activeFEMALayer) return undefined;

        const _bounds = map.getBounds();

        setBounds(_bounds);

        return () => {
            setBounds(null);
        };
    }, [map, activeFEMALayer]);

    useEffect(() => {
        if (!map || !bounds || !activeFEMALayer) return undefined;

        const zoomLevel = map.getZoom();

        if (zoomLevel < MIN_INTERACTIVE_ZOOM.FEMA_ZONES) return undefined;

        const abortController = new AbortController();

        addZonesToMap({
            _map: map,
            geometry: bounds.toArray().flat().join(","),
            resultOffset: 0,
            signal: abortController.signal,
            tolerance: getTolerance(zoomLevel),
            features: [],
            requiredFields: activeFEMALayer.requiredFields,
        });

        return () => {
            abortController.abort();
        };
    }, [map, addZonesToMap, bounds, activeFEMALayer]);

    return interactiveLayers;
};
