import { useCallback, useEffect, useState } from "react";
import type { FeatureCollection } from "geojson";
import { debounce } from "lodash-es";
import { FillLayer, LineLayer, LngLatBounds, Map } from "mapbox-gl";
import { StlNotification } from "@common/components";
import { MIN_INTERACTIVE_ZOOM } from "@common/components/baseMap/mapLayers/mapLayers.constants";
import {
    ENHANCED_LAYERS,
    TEnhancedLayer,
} from "@common/features/mapEnhancedLayers/state/mapEnhancedLayers.constants";
import { ArcgisFeatureService } from "@common/services/arcgisFeature.service";
import { getFirstLabelLayerId, removeSource } from "@common/utils/mapUtils";

const recordCount = 2000;

const SOURCE_ID = "stl:cs-j40-source";
const LAYER_ID = "stl:j-40-geom-layer";
const OUTLINE_LAYER_ID = "stl:j-40-geom-outline-layer";

export const ZOOM_STEPS = {
    MIN: 7.5,
    MIDDLE: 10,
};

const getTolerance = (zoom: number) => {
    if (zoom < ZOOM_STEPS.MIN) return 0.02;

    if (zoom < ZOOM_STEPS.MIDDLE) return 0.01;

    return 0;
};

const getBeforeLayerId = (map: Map) => {
    const { layers = [] } = map.getStyle();

    const firstStlLayerId = layers.find(layer => layer.id.includes("stl:"))?.id;

    return firstStlLayerId || getFirstLabelLayerId(map);
};

export const useDACZoneLayers = ({
    map,
    show,
    selectedZone,
    where = "SN_C=1",
    style = ENHANCED_LAYERS.DAC_ZONES.style,
    sourceIdPostfix = "",
}: {
    map: mapboxgl.Map | null;
    show: boolean;
    selectedZone?: any;
    where?: string;
    style?: TEnhancedLayer["style"];
    sourceIdPostfix?: string;
}) => {
    const [bounds, setBounds] = useState<null | LngLatBounds>(null);
    const [staticLayers, setStaticLayers] = useState<LineLayer[]>([]);
    const [interactiveLayers, setInteractiveLayers] = useState<FillLayer[]>([]);

    const sourceId = SOURCE_ID + sourceIdPostfix;

    useEffect(() => {
        if (!map || map.getSource(sourceId) || !show) return undefined;

        map.addSource(sourceId, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [],
            },
            promoteId: ENHANCED_LAYERS.DAC_ZONES.featureIdProperty,
        });

        const labelId = getBeforeLayerId(map);

        const outlineLayer: LineLayer = {
            id: OUTLINE_LAYER_ID + sourceIdPostfix,
            type: "line",
            minzoom: MIN_INTERACTIVE_ZOOM.DAC_ZONES,
            source: sourceId,
            paint: {
                "line-width": [
                    "step",
                    ["zoom"],
                    style.lineWidth / 3,
                    ZOOM_STEPS.MIN,
                    style.lineWidth / 2,
                    ZOOM_STEPS.MIDDLE,
                    style.lineWidth,
                ],
                "line-color": style.color,
            },
        };
        map.addLayer(outlineLayer, labelId);
        setStaticLayers([outlineLayer]);

        const layer: FillLayer = {
            id: LAYER_ID + sourceIdPostfix,
            type: "fill",
            minzoom: MIN_INTERACTIVE_ZOOM.DAC_ZONES,
            source: sourceId,
            paint: {
                "fill-color": style.color,
                "fill-opacity": style.fillOpacity,
            },
            filter: [
                "any",
                ["==", ["get", "N_CLT"], 1],
                ["==", ["get", "N_ENY"], 1],
                ["==", ["get", "N_HLTH"], 1],
                ["==", ["get", "N_HSG"], 1],
                ["==", ["get", "N_PLN"], 1],
                ["==", ["get", "N_TRN"], 1],
                ["==", ["get", "N_WTR"], 1],
                ["==", ["get", "N_WKFC"], 1],
            ],
        };
        map.addLayer(layer, labelId);
        setInteractiveLayers([layer]);

        return () => {
            if (map.getSource(sourceId)) {
                removeSource(map, sourceId);
            }
        };
    }, [style, map, show, sourceId, sourceIdPostfix]);

    useEffect(() => {
        if (
            !map ||
            !interactiveLayers.length ||
            !map.getSource(interactiveLayers[0].source as string)
        )
            return;

        const allLayers = [...interactiveLayers, ...staticLayers];
        const { featureIdProperty } = ENHANCED_LAYERS.DAC_ZONES;
        const zoneId = selectedZone?.[featureIdProperty] || "";

        allLayers.forEach(layer => {
            if (layer.id.startsWith(LAYER_ID)) {
                map.setPaintProperty(layer.id, "fill-color", [
                    "case",
                    ["boolean", ["feature-state", "hover"], false],
                    style.hoverColor,
                    ["==", ["get", featureIdProperty], zoneId],
                    style.selectColor,
                    style.color,
                ]);
            } else if (layer.id.startsWith(OUTLINE_LAYER_ID)) {
                map.setPaintProperty(layer.id, "line-color", [
                    "case",
                    ["boolean", ["feature-state", "hover"], false],
                    style.hoverColor,
                    ["==", ["get", featureIdProperty], zoneId],
                    style.selectLineColor,
                    style.color,
                ]);
            }
        });
    }, [map, interactiveLayers, staticLayers, style, selectedZone]);

    const addZonesToMap = useCallback(
        ({
            _map,
            geometry,
            resultOffset,
            signal,
            tolerance,
            features,
        }: {
            _map: Map;
            geometry: string;
            resultOffset: number;
            signal: AbortSignal;
            tolerance: number;
            features: FeatureCollection["features"];
        }) => {
            ArcgisFeatureService.getPaginatedDacZonesLayer(
                {
                    where,
                    geometry,
                    resultOffset,
                    resultRecordCount: recordCount,
                    quantizationParameters: {
                        tolerance,
                    },
                },
                signal,
            )
                .then(result => {
                    const source = _map.getSource(sourceId);

                    if (source.type !== "geojson") return;

                    const { properties, ...featureCollection } = result;

                    const updatedFeatureList = [...features, ...featureCollection.features];

                    if (properties?.exceededTransferLimit) {
                        addZonesToMap({
                            _map,
                            geometry,
                            resultOffset: resultOffset + recordCount,
                            signal,
                            tolerance,
                            features: updatedFeatureList,
                        });
                    } else {
                        source.setData({
                            type: "FeatureCollection",
                            features: updatedFeatureList,
                        });
                    }
                })
                .catch(error => {
                    if (error) {
                        StlNotification.error(error);
                    }
                });
        },
        [sourceId, where],
    );

    // Subscribe on bound change
    useEffect(() => {
        if (!map || !show) return undefined;

        const updateBounds = debounce(() => {
            const _bounds = map.getBounds();

            setBounds(_bounds);
        }, 300);

        map.on("moveend", updateBounds);

        return () => {
            map.off("moveend", updateBounds);
        };
    }, [map, show]);

    // Set bounds on DAC layer checkbox select
    useEffect(() => {
        if (!map || !show) return undefined;

        const _bounds = map.getBounds();

        setBounds(_bounds);

        return () => {
            setBounds(null);
        };
    }, [map, show]);

    useEffect(() => {
        if (!map || !bounds || !show) return undefined;

        const zoomLevel = map.getZoom();

        if (zoomLevel < MIN_INTERACTIVE_ZOOM.DAC_ZONES) return undefined;

        const abortController = new AbortController();

        addZonesToMap({
            _map: map,
            geometry: bounds.toArray().flat().join(","),
            resultOffset: 0,
            signal: abortController.signal,
            tolerance: getTolerance(zoomLevel),
            features: [],
        });

        return () => {
            abortController.abort();
        };
    }, [map, addZonesToMap, bounds, show]);

    return interactiveLayers;
};
