import { merge } from "lodash-es";
import { TOrg } from "@app/bigBang/admin/orgs/orgs.types";
import { ORG_COUNTRIES } from "@common/constants/analysis.constants";
import { ORG_TYPES } from "@common/constants/orgTypes.constants";
import { EMeasurementUnits } from "@common/features/orgs/orgs.types";
import {
    IOrganization,
    IOrganizationData,
    IOrgInfoAPI,
} from "@common/services/server/adminApi.types";
import { arrayIncludes } from "@common/utils/arrayIncludes";
import { INITIAL_ORG_STATE_OBJ } from "./manageOrg.constants";

export const getCrmIdFieldFormatWarning = (fieldName: string) => {
    const fieldFormat =
        "https://streetlightdata<string>.lightning.force.com/<18-character string>";
    return `${fieldName} should have the following format: ${fieldFormat}`;
};

export const getChildOrgsList = (orgsList: Array<IOrgInfoAPI>) =>
    orgsList.filter(org => org.org_type !== ORG_TYPES.PARENT.id);

export const getParentOrgsList = (orgsList: Array<IOrgInfoAPI>) =>
    orgsList.filter(org => org.org_type === ORG_TYPES.PARENT.id);

export const getSelectedChildOrgIds = (org: TOrg, childOrgsList: Array<IOrgInfoAPI>) => {
    if (org.type !== ORG_TYPES.PARENT.id) return [];

    return childOrgsList.reduce((result, _org) => {
        if (_org.parent_org_id === org.id) {
            return [...result, _org.org_id];
        }

        return result;
    }, [] as Array<number>);
};

const getEnterpriseData = (org: TOrg) => {
    return {
        is_enterprise_org: true,
        task_number: org.taskNumber,
        charge_code: org.chargeCode,
    };
};

export const orgDataToState = (org: IOrganization): TOrg =>
    merge(
        {},
        { ...INITIAL_ORG_STATE_OBJ },
        {
            id: org.org_id,
            name: org.org_name,
            nickname: org.org_nickname,
            type: org.org_type,
            moduleType: org.module_type,
            parentOrgId: org.parent_org_id,
            childOrgIds: org.child_orgs,
            crmAccountId: org.crm_account_id || "",
            crmStreetlightAccountUri: org.crm_streetlight_account_uri || "",
            orgNote: org.org_note || "",
            hasActiveInsightSubscription: org.has_active_subscription,
            hasActiveConstructionSubscription:
                org.construction_subscriptions?.has_active_subscription || false,
            hasActiveSafetyPrioritizeSubscription:
                org.safety_prioritize_subscriptions?.has_active_subscription || false,
            hasActiveTrafficMonitorSubscription:
                org.traffic_monitor_subscriptions?.has_active_subscription || false,
            isActive: org.is_active,
            isStudyEnabled: org.is_study_enabled,
            countries: [
                ...(org.country_ca ? [ORG_COUNTRIES.CA.id] : []),
                ...(org.country_us ? [ORG_COUNTRIES.US.id] : []),
            ],
            measurementUnit: org.measurement_unit,
            skilljarNamedGroup: org.skilljar_named_group,
            productAccessTypes: org.product_access_types,
            taskNumber: org.task_number,
            chargeCode: org.charge_code,
            isEnterpriseOrg: org.is_enterprise_org,
        },
    );

export const orgStateToData = (org: TOrg): IOrganizationData => ({
    ...(org.id && { org_id: org.id }),
    org_name: org.name,
    org_nickname: org.nickname,
    org_type: org.type!,
    ...(org.type !== ORG_TYPES.PARENT.id && { parent_org: { org_id: org.parentOrgId as number } }),
    ...(org.type === ORG_TYPES.PARENT.id && { child_orgs: org.childOrgIds }),
    crm_account_id: org.crmAccountId,
    crm_streetlight_account_uri: org.crmStreetlightAccountUri,
    org_note: org.orgNote,
    is_study_enabled:
        org.type !== ORG_TYPES.MARKETING_SUBSCRIPTION.id ? false : org.isStudyEnabled,
    country_ca: arrayIncludes(org.countries, ORG_COUNTRIES.CA.id),
    country_us: arrayIncludes(org.countries, ORG_COUNTRIES.US.id),
    measurement_unit: org.measurementUnit as EMeasurementUnits,
    skilljar_named_group: org.skilljarNamedGroup,
    product_access_types: org.productAccessTypes,
    ...(org.isEnterpriseOrg && getEnterpriseData(org)),
});
