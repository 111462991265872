import { ReactNode } from "react";
import classNames from "classnames";
import "./headerLayout.less";

const DEFAULT_LOGO_URL: string = "/img/stl-logo-only.svg";

type TProps = {
    logoContent?: ReactNode;
    children: ReactNode;
    logoUrl?: string;
    onClickLogo?: () => void;
    className?: string;
};

export const StlAppHeader = ({
    logoContent,
    logoUrl,
    onClickLogo = () => {},
    children,
    className,
}: TProps): JSX.Element => (
    <header className={classNames("stl-header-banner", className)} role="banner">
        <div className="stl-header-logo-container">
            <img
                role="presentation"
                className="header-logo"
                src={logoUrl || DEFAULT_LOGO_URL}
                alt="StreetLightData logo"
                onClick={onClickLogo}
            />
            {logoContent}
        </div>
        {children}
    </header>
);
