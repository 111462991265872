import { batch } from "react-redux";
import { merge } from "lodash-es";
import { setCalibrationCode } from "@app/analysis/basics/state/basics.actions";
import { validCalibrationCodeSelector } from "@app/analysis/basics/state/basics.selectors";
import { setAnalysisTypeDefaults } from "@app/analysis/state/analysisConfiguration.actions";
import type {
    TCreateAnalysisType,
    TZoneMode,
} from "@app/analysis/state/analysisConfiguration.constants";
import type { IResetConfig } from "@app/analysis/state/analysisConfiguration.types";
import { GENERAL_INITIAL_STATE, IGeneralState } from "@app/analysis/state/general/general.state";
import { resetReducer as resetChooseZonesReducer } from "@app/analysis/zones/chooseZones/state/chooseZones.actions";
import type { TAppDispatch } from "@app/store";
import type { TGetState } from "@app/store/root.reducer";
import { actions } from "./general.reducer";

export const {
    resetAnalysisSizeReviewData,
    resetState,
    resetUniqueZoneData,
    saveAnalysisReset,
    saveAnalysisSuccess,
    setActiveTab,
    setAnalysisActionError,
    setAnalysisTypeCode: _setAnalysisTypeCode,
    setAnalysisValidation,
    setGeneralInitialData,
    setGeneralUiState,
    setIs15MinuteBinsActive,
    setIsAnalysisTypeChanged,
    setIsShowAllAnalysesAllowed,
    setProjectFolder,
    setScreenMode,
    setZonesMode: _setZonesMode,
    showAnalysisSizeReviewModal,
    showPreviewAnalysis,
    showZoneQuotaModal,
    updateAnalysisVersionId,
    updateState,
} = actions;

export const resetGeneralReducer =
    (generalSwitchConfig: Partial<IGeneralState>) => (dispatch: TAppDispatch) => {
        if (generalSwitchConfig) {
            return dispatch(updateState(merge({}, GENERAL_INITIAL_STATE, generalSwitchConfig)));
        }
        return dispatch(resetState());
    };

export const setAnalysisTypeCode =
    (analysisTypeCode: TCreateAnalysisType["code"], config?: IResetConfig) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        dispatch(_setAnalysisTypeCode(analysisTypeCode));

        const newState = getState();
        batch(() => {
            dispatch(setCalibrationCode(validCalibrationCodeSelector(newState)));
            dispatch(setAnalysisTypeDefaults(analysisTypeCode, config));
        });
    };

export const setZonesMode = (zonesMode: TZoneMode) => (dispatch: TAppDispatch) => {
    // INST-17373: Reset both zone modes state to prevent broken correlations between them.
    dispatch(resetChooseZonesReducer());

    return dispatch(_setZonesMode(zonesMode));
};
