import { batch } from "react-redux";
import type { Feature, LineString } from "geojson";
import { isEqual } from "lodash-es";
import { v4 as uuidv4 } from "uuid";
import * as generalActions from "@app/analysis/state/general/general.actions";
import { createValidateField } from "@app/analysis/validation";
import { setZonesValidation } from "@app/analysis/zones/chooseZones/state/chooseZones.actions";
import { CHOOSE_ZONES_INITIAL_STATE } from "@app/analysis/zones/chooseZones/state/chooseZones.state";
import type { TAppDispatch } from "@app/store";
import type { TGetState } from "@app/store/root.reducer";
import { ZONE_KINDS } from "@common/constants/zoneLibrary.constants";
import { TDirectionOption } from "@common/features/directions/directions.constants";
import {
    getDefaultGateDirection,
    validateGate,
    validateIntersectionName,
} from "@common/features/intersections/intersection.helpers";
import type {
    IAvailableIntersection,
    IHoveredGate,
    IIntersection,
    IIntersectionZoneGate,
} from "@common/features/intersections/intersection.types";
import { getIsTMCAnalysis } from "@common/helpers/analysis";
import { AnalysesApiService } from "@common/services/server/analysesApi.service";
import type {
    IAnalysis,
    IAnalysisIntersectionZone,
    IValidateIntersectionsData,
} from "@common/services/server/analysesApi.types";
import { HttpService } from "@common/services/server/http.service";
import { ZonesApiService } from "@common/services/server/zonesApi.service";
import type { ILineZoneAPI } from "@common/services/server/zonesApi.types";
import { MAP_MODES, NO_SELECTED_INTERSECTION_TEXT } from "./tmcChooseZones.constants";
import {
    getIntersectionWarningsMap,
    getIntersectionZonesFromAnalysis,
    getIsReusedIntersection,
    transformIntersectionForValidation,
} from "./tmcChooseZones.helpers";
import { actions } from "./tmcChooseZones.reducer";
import {
    getEditableFeature,
    getHoveredGate,
    getHoveredIntersectionZoneId,
    getIntersectionZones,
    getIntersectionZonesList,
    getSelectedIntersection,
    getValidatedIntersectionZones,
} from "./tmcChooseZones.selectors";

export const {
    setMapMode,
    setSelectedGateId,
    deleteIntersection,
    setEditableFeature,
    addIntersectionZone,
    reuseIntersections,
    setInitialIntersectionZones,
    setIntersectionZones,
    updateIntersectionZone,
    updateAvailableIntersections,
    setHoveredIntersectionZoneId,
    setSelectedIntersectionZoneId,
    setHoveredGate: _setHoveredGate,
    resetTMCChooseZonesState: resetTMCChooseZonesReducer,
} = actions;

export const setTMCZonesTabValidation =
    (intersectionName: string, gates: Array<IIntersectionZoneGate>) =>
    (dispatch: TAppDispatch) => {
        const hasEmptyName = !intersectionName.trim().length;
        const hasGateErrors = gates.some(gate => gate.errors?.length);
        const validationResult = [
            ...(hasEmptyName ? ["Please name the intersection."] : []),
            ...(hasGateErrors ? ["Gates data are incomplete"] : []),
        ].join(". ");

        dispatch(
            setZonesValidation({
                ...CHOOSE_ZONES_INITIAL_STATE.validation.fields,
                chooseZones: createValidateField(validationResult),
            }),
        );
    };

export const selectIntersection =
    (intersectionId: IIntersection["id"] | null, withGate = true) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        if (intersectionId === null) {
            batch(() => {
                dispatch(setEditableFeature(null));
                dispatch(setSelectedGateId(null));
                dispatch(setSelectedIntersectionZoneId(null));
            });
            return;
        }

        const state = getState();
        const intersectionObj = getIntersectionZones(state)[intersectionId];

        if (!intersectionObj) {
            return;
        }

        batch(() => {
            if (withGate) {
                const gateIdToBeSelected =
                    intersectionObj.gates.find(gate => gate.errors?.length)?.id ??
                    intersectionObj.gates[0].id;

                dispatch(setSelectedGateId(gateIdToBeSelected));
            }

            dispatch(setSelectedIntersectionZoneId(intersectionId));
        });
    };

export const setSelectedGate =
    (selectedGate: IIntersectionZoneGate | null) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();
        const selectedGateId = selectedGate?.id ?? null;
        const selectedIntersection = getSelectedIntersection(state);

        const intersection = {
            ...selectedIntersection,
            selectedGate,
        } as IIntersection;

        batch(() => {
            dispatch(setSelectedGateId(selectedGateId));
            dispatch(updateIntersectionZone(intersection));
        });
    };

export const editSelectedGate = (feature: Feature<LineString>) => (dispatch: TAppDispatch) => {
    batch(() => {
        dispatch(setMapMode(MAP_MODES.EDIT_GATE));
        dispatch(setEditableFeature(feature));
    });
};

export const saveSelectedGate =
    (selectedGate?: IIntersectionZoneGate | null) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();
        const editableFeature = getEditableFeature(state);
        const intersection = getSelectedIntersection(state);

        if (!selectedGate) return;

        let _selectedGate: IIntersectionZoneGate;
        let isEditedIntersection = false;
        const newGates = intersection!.gates.map(_gate => {
            if (_gate.id !== selectedGate.id) return _gate;

            isEditedIntersection = !isEqual(
                _gate.line_geom,
                JSON.stringify(editableFeature?.geometry),
            );
            _selectedGate = editableFeature
                ? {
                      ..._gate,
                      line_geom: JSON.stringify(editableFeature?.geometry),
                  }
                : selectedGate;

            return _selectedGate;
        });

        const intersectionZone = {
            ...intersection,
            selectedGate: _selectedGate!,
            gates: newGates,
            ...(intersection?.zone_id
                ? {
                      is_edited_intersection:
                          intersection.is_edited_intersection || isEditedIntersection,
                  }
                : {}),
        } as IIntersection;
        batch(() => {
            dispatch(updateIntersectionZone(intersectionZone));
            dispatch(setEditableFeature(null));
            if (_selectedGate?.line_geom) {
                dispatch(validateDrawnGate(_selectedGate.id));
            }
        });
    };

export const setDefaultGateData =
    (selectedGateId: string, editableFeature: Feature<LineString>) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();
        const selectedIntersection = getSelectedIntersection(state);

        if (!selectedIntersection?.gates) return;

        const { newGates, _selectedGate } = selectedIntersection.gates.reduce(
            (result, _gate) => {
                const { road, role, line_geom } = _gate;

                const gate = {
                    road,
                    role,
                    line_geom:
                        _gate.id !== selectedGateId
                            ? line_geom
                            : JSON.stringify(editableFeature?.geometry),
                } as IIntersectionZoneGate;

                if (_gate.id === selectedGateId) result._selectedGate = gate;
                result.newGates.push(gate);

                return result;
            },
            {
                newGates: [],
                _selectedGate: null,
            } as {
                newGates: IIntersectionZoneGate[];
                _selectedGate: IIntersectionZoneGate | null;
            },
        );

        const zones = transformIntersectionForValidation([
            {
                name: selectedIntersection?.zone_name,
                gates: newGates,
            } as Partial<IIntersection>,
        ]) as unknown as IAnalysisIntersectionZone[];

        const options: IValidateIntersectionsData = {
            zones,
        };

        AnalysesApiService.validateIntersections(options)
            .then(({ data }) => {
                const intersectionZones = getIntersectionZonesList(getState());

                const validatedIntersection = intersectionZones.find(intersection => {
                    return intersection.gates.some(gate => gate.id === selectedGateId);
                })!;

                const selectedGate = data[0].gates.find(
                    gate => gate.gate_role === _selectedGate!.role,
                );

                if (!selectedGate) return;

                const gates = validatedIntersection.gates.map(gate => {
                    if (gate.id !== selectedGateId) return gate;

                    return {
                        ...gate,
                        road:
                            _selectedGate!.road ||
                            `${selectedGate.gate_role} - ${
                                selectedGate.osm_name ? selectedGate.osm_name.split(" / ")[0] : ""
                            }`,
                        oneWay: !selectedGate.is_bidi,
                        direction: getDefaultGateDirection(
                            !selectedGate.is_bidi,
                            gate.role,
                        ) as TDirectionOption["id"],
                        geometryWarnings: [],
                        errors: [
                            ...(gate.invalidRole || []),
                            ...(gate.invalidName || []),
                            ...(gate.invalidGeometry || []),
                        ],
                    };
                });

                dispatch(updateIntersectionZone({ ...validatedIntersection, gates }));
            })
            .catch(error => {
                const message = error?.response?.data?.message || error.message;
                dispatch(generalActions.setAnalysisActionError(message));
            });
    };

export const validateDrawnGate =
    (selectedGateId: string) => (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const zones = transformIntersectionForValidation(
            getValidatedIntersectionZones(state),
        ) as unknown as IAnalysisIntersectionZone[];

        const options: IValidateIntersectionsData = {
            zones,
        };

        return AnalysesApiService.validateIntersections(options)
            .then(({ data }) => {
                const intersectionZones = getIntersectionZonesList(getState());

                const validatedIntersection = intersectionZones.find(intersection => {
                    return intersection.gates.some(gate => gate.id === selectedGateId);
                })!;

                if (!data.length) {
                    const gates = validatedIntersection.gates.map(gate => {
                        if (gate.id !== selectedGateId) {
                            return gate;
                        }

                        return {
                            ...gate,
                            geometryWarnings: [],
                            errors: [
                                ...(gate.invalidRole || []),
                                ...(gate.invalidName || []),
                                ...(gate.invalidGeometry || []),
                            ],
                        };
                    });

                    dispatch(updateIntersectionZone({ ...validatedIntersection, gates }));

                    return undefined;
                }

                const intersectionsMap = new Map(
                    data.map(item => [item.intersection_name, getIntersectionWarningsMap(item)]),
                );

                const intersectionWithFailedValidation = intersectionsMap.get(
                    validatedIntersection.zone_name,
                );

                if (!intersectionWithFailedValidation) return true;

                const gates = validatedIntersection.gates.map(gate => {
                    if (!gate.line_geom) {
                        return gate;
                    }
                    const geometryWarnings = intersectionWithFailedValidation.get(gate.role) ?? [];

                    const _gate = validateGate({ gate, gates: validatedIntersection.gates });

                    const errors = [
                        ..._gate.invalidRole,
                        ..._gate.invalidName,
                        ..._gate.invalidGeometry,
                        ...geometryWarnings,
                    ];

                    return { ...gate, errors: errors, geometryWarnings };
                });

                const newZonesValidation = {
                    ...CHOOSE_ZONES_INITIAL_STATE.validation.fields,
                    chooseZones: { isInvalid: true, touched: true, reasons: [] },
                };

                batch(() => {
                    dispatch(updateIntersectionZone({ ...validatedIntersection, gates }));
                    dispatch(setZonesValidation(newZonesValidation));
                });

                return true;
            })
            .catch(error => {
                // handled the same way as in saveAnalysis()
                const message = error?.response?.data?.message || error.message;
                dispatch(generalActions.setAnalysisActionError(message));
            });
    };

export const validateAnalysisIntersections =
    () => (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const zones = transformIntersectionForValidation(
            getValidatedIntersectionZones(state),
        ) as unknown as IAnalysisIntersectionZone[];

        const options: IValidateIntersectionsData = {
            zones,
        };

        return AnalysesApiService.validateIntersections(options)
            .then(({ data }) => {
                if (!data?.length) return undefined;

                const intersectionZones = getIntersectionZonesList(getState());
                let hasErrors = false;

                const intersectionsMap = new Map(
                    data.map(item => {
                        hasErrors = item.gates.some(({ error_msgs }) => error_msgs.length);

                        return [item.intersection_name, getIntersectionWarningsMap(item)];
                    }),
                );

                const validatedIntersections = intersectionZones.map(intersection => {
                    const intersectionWithFailedValidation = intersectionsMap.get(
                        intersection.zone_name,
                    );
                    if (!intersectionWithFailedValidation) {
                        return intersection;
                    }

                    const gates = intersection.gates.map(gate => {
                        const geometryWarnings =
                            intersectionWithFailedValidation.get(gate.role) ?? [];

                        const _gate = validateGate({ gate, gates: intersection.gates });

                        const errors = [
                            ..._gate.invalidRole,
                            ..._gate.invalidName,
                            ..._gate.invalidGeometry,
                            ...geometryWarnings,
                        ];

                        return { ...gate, errors: errors, geometryWarnings };
                    });

                    return { ...intersection, gates };
                });

                const newZonesValidation = {
                    ...CHOOSE_ZONES_INITIAL_STATE.validation.fields,
                    chooseZones: { isInvalid: true, touched: true, reasons: [] },
                };

                batch(() => {
                    validatedIntersections.forEach(validatedIntersection => {
                        dispatch(updateIntersectionZone(validatedIntersection));
                    });
                    dispatch(setZonesValidation(newZonesValidation));
                });

                return { hasWarnings: true, hasErrors };
            })
            .catch(error => {
                // handled the same way as in saveAnalysis()
                const message = error?.response?.data?.message || error.message;
                dispatch(generalActions.setAnalysisActionError(message));
            });
    };

export const validateImportedIntersection =
    () => (dispatch: TAppDispatch, getState: TGetState) => {
        const intersectionZones = getIntersectionZonesList(getState());
        const validationResult = !intersectionZones.length ? NO_SELECTED_INTERSECTION_TEXT : "";
        const newZonesValidation = {
            ...CHOOSE_ZONES_INITIAL_STATE.validation.fields,
            chooseZones: createValidateField(validationResult),
        };
        dispatch(setZonesValidation(newZonesValidation));
    };

export const setValidatedIntersections =
    (validationResult: string) => (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();
        const intersectionZones = getIntersectionZonesList(state);

        if (!validationResult || !intersectionZones.length) return;

        const validatedIntersectionsMap = intersectionZones.reduce((res, intersection) => {
            const _intersection = validateIntersectionName(intersection);

            const _gates = _intersection.gates.map((gate, idx, gates) =>
                validateGate({ gate, gates }),
            );

            res[_intersection.id] = { ..._intersection, gates: _gates };
            return res;
        }, {} as Record<string, IIntersection>);

        dispatch(setIntersectionZones(validatedIntersectionsMap));
    };

export const setHoveredGate =
    (gate: IHoveredGate | null) => (dispatch: TAppDispatch, getState: TGetState) => {
        const hoveredGate = getHoveredGate(getState());

        if (gate?.zone_id === hoveredGate?.zone_id) return null;

        return dispatch(_setHoveredGate(gate));
    };

export const setHoveredIntersectionId =
    (intersectionId: IIntersection["id"] | null) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const hoveredIntersectionZoneId = getHoveredIntersectionZoneId(getState());

        if (intersectionId === hoveredIntersectionZoneId) return null;

        return dispatch(setHoveredIntersectionZoneId(intersectionId));
    };

export const setImportedIntersection = (zone: IIntersection) => (dispatch: TAppDispatch) => {
    batch(() => {
        dispatch(setIntersectionZones({ [zone.zone_id!]: zone }));
        dispatch(validateImportedIntersection());
    });
};

export const fetchAvailableIntersectionZones = () => (dispatch: TAppDispatch) => {
    return ZonesApiService.getZonesByZoneKindId(ZONE_KINDS.INTERSECTION.id[0])
        .then(({ zones }) => {
            // 'set_id' and 'set_name' properties are required for ZoneSetsPickerModal
            const _zones = zones.map(zone => ({
                ...zone,
                set_id: zone.zone_id,
                set_name: zone.zone_name,
            })) as Array<IAvailableIntersection>;
            dispatch(updateAvailableIntersections(_zones));
        })
        .catch(HttpService.silentError);
};

export const fetchIntersectionZoneById = (zoneId: number) => (dispatch: TAppDispatch) => {
    return ZonesApiService.getZoneById(ZONE_KINDS.INTERSECTION.id[0], zoneId)
        .then(({ data }) => {
            const zone = {
                ...data,
                id: String(data.zone_id),
                gates: (data as ILineZoneAPI).gates!.map(gate => ({
                    ...gate,
                    id: uuidv4(),
                })) as Array<IIntersectionZoneGate>,
                isImported: true,
            } as IIntersection;

            batch(() => {
                dispatch(setIntersectionZones({ [zone.zone_id!]: zone }));
                dispatch(validateImportedIntersection());
            });
        })
        .catch(HttpService.silentError);
};

export const setTMCInitialData = (analysisData: IAnalysis) => (dispatch: TAppDispatch) => {
    const { project_type: analysisType, intersection_zones: analysisIntersectionZones } =
        analysisData;
    if (!getIsTMCAnalysis(analysisType)) return;

    const hasReusedIntersections =
        analysisIntersectionZones.length &&
        analysisIntersectionZones.every(zone => getIsReusedIntersection(zone));
    if (hasReusedIntersections) {
        const intersectionZoneId = analysisIntersectionZones[0].zone_id;
        dispatch(fetchIntersectionZoneById(intersectionZoneId));
    } else {
        dispatch(
            setInitialIntersectionZones(
                getIntersectionZonesFromAnalysis(analysisIntersectionZones),
            ),
        );
    }
};
