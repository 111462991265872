import { ReactNode } from "react";
import "./authLayout.less";

type TProps = {
    children: ReactNode;
};

export const AuthLayout = ({ children }: TProps) => {
    return (
        <main className="stl-entry-main" role="main">
            {children}
        </main>
    );
};
