import { FormattedMessage } from "react-intl";
import { COLORS as MAIN_COLORS } from "@common/constants/color.constants";

export const LINE_COLOR = MAIN_COLORS.MID_GREY;

export const DEFAULT_AREAS = [
    [5, 95],
    [25, 75],
];

export const DEFAULT_SPEED_METRICS = [5, 25, 75, 85, 95];

export const COLORS = {
    default: {
        regular: "#F0CF7F",
        mean: "#EDE3CB",
        range1: "rgba(240, 207, 127, 0.6)",
        range2: "rgba(240, 207, 127, 0.3)",
        rangeOpacity: 0.5,
    },
    peakHour: {
        regular: "#3485FF",
        mean: "#88B8F6",
        range1: "rgba(136, 184, 246, 0.6)",
        range2: "rgba(136, 184, 246, 0.3)",
        rangeOpacity: 0.5,
    },
};
export const PEAK_COLORS = [
    COLORS.peakHour.regular,
    COLORS.peakHour.mean,
    COLORS.peakHour.range1,
    COLORS.peakHour.range2,
];

export const METRIC_TYPE = {
    volume: {
        id: "volume",
        name: "Volume",
        aggregation: "Sum",
        chartType: "timeDistributionMetric",
        ignoredFilters: null,
        dataField: "bins",
    },
    speed: {
        id: "speed",
        name: "Speed",
        aggregation: "Avg",
        chartType: "timeDistributionPctl",
        ignoredFilters: ["visualization_property"],
        dataField: "bins",
    },
} as const;
export type TMetricTypes = typeof METRIC_TYPE;
export type TMetricType = typeof METRIC_TYPE[keyof typeof METRIC_TYPE];

export const TIME_DISTRIBUTION_CLASS_NAME = "stl-viz3-time-distribution";

export const DATA_FETCHING_ERROR_TEXT = (
    <FormattedMessage
        id="viz3.widgets.timeDistributionChart.errorText"
        defaultMessage="Error occurred while fetching bins metric"
    />
);

export const GROUPING_OPTIONS = {
    dayPart: { id: "dayPart", code: "day_part", name: "Day Part" },
    dayType: { id: "dayType", code: "day_type", name: "Day Type" },
} as const;
export type TGroupingOptionCode = typeof GROUPING_OPTIONS[keyof typeof GROUPING_OPTIONS]["code"];

export const GROUPING_OPTIONS_LIST = Object.values(GROUPING_OPTIONS).map(option => ({
    label: option.name,
    value: option.id,
}));
export type TGroupingOption = typeof GROUPING_OPTIONS_LIST[number];

export const SORTING_OPTIONS = {
    chronological: { id: "chronological", name: "Chronological" },
    metricValueDescending: { id: "metricValueDescending", name: "Metric Value (descending)" },
    metricValueAscending: { id: "metricValueAscending", name: "Metric Value (ascending)" },
};

export const SORTING_OPTIONS_LIST = Object.values(SORTING_OPTIONS).map(option => ({
    label: option.name,
    value: option.id,
}));

export const TIME_DISTRIBUTION_WIDGET_KEY = "timeDistribution";
