import { uniq } from "lodash-es";
import moment from "moment";
import { ANALYSIS_NAME_MAX_LENGTH } from "@app/analysis/basics/state/basics.constants";
import { getCopiedAnalysisName } from "@app/analysis/state/analysisConfiguration.helpers";
import { IAnalysisWarnings } from "@app/analysis/state/analysisConfiguration.types";
import {
    LIGHTNING_ANALYSIS_TYPES,
    RAMP_ROAD_NAMES,
    SCREEN_MODES,
    TLightningAnalysisTypeIds,
    TRampRoadNames,
    TRampRoadNamesKey,
} from "@app/analysisLightning/base/state/analysisLightning.constants";
import type {
    IMonths,
    ISegment,
    ITimePeriod,
    TGeographyType,
} from "@app/analysisLightning/base/state/analysisLightning.types";
import type { ILocation } from "@common/components/geocoder/geocoder";
import {
    ROAD_TYPES,
    TRoadId,
    TRoadType,
    TRoadTypeKey,
} from "@common/features/zonesManager/state/zonesManager.constants";
import { getIsRVAnalysis } from "@common/helpers/analysis";
import type {
    IAnalysis,
    IAnalysisDateRange,
    ITableAnalysisInfo,
    TLightningDraftApi,
} from "@common/services/server/analysesApi.types";
import { arrayIncludes } from "@common/utils/arrayIncludes";

export const getLightningAnalysisActionsModule = (analysisType: TLightningAnalysisTypeIds) => {
    const { path: lightningCode } = LIGHTNING_ANALYSIS_TYPES[analysisType];

    return import(`../../${lightningCode}/state/${lightningCode}.actions.ts`);
};

export const getCopyLightningAnalysisValidation = (analysisData: IAnalysis) => {
    const warnings = {} as IAnalysisWarnings;
    if (getIsRVAnalysis(analysisData.project_type)) {
        warnings.roadTypes = {
            value: true,
            message:
                "Please note: Roadway segments classified as “links” are no longer permitted and will be removed from the copied analysis setup.",
        };
    }
    return { hasWarnings: !!Object.keys(warnings).length, warnings };
};

export const getGeographyTypeName = (
    availableGeographyTypes: Array<TGeographyType>,
    id: TGeographyType["id"][number],
) => availableGeographyTypes.find(type => type.id[0] === id)?.name;

export const getMapErrors = ({
    mapErrors,
    isRegionVisible,
    errorText,
}: {
    mapErrors?: string[];
    isRegionVisible: boolean;
    errorText: string;
}): string[] | null | undefined => {
    if (!isRegionVisible) {
        let updatedMapErrors;

        if (mapErrors) {
            const hasOutsideOfViewError = mapErrors.some(error => error === errorText);

            updatedMapErrors = hasOutsideOfViewError ? mapErrors : [...mapErrors, errorText];
        } else {
            updatedMapErrors = [errorText];
        }

        return updatedMapErrors;
    }

    if (!mapErrors) return undefined;

    const filteredMapErrors = mapErrors.filter(error => error !== errorText);

    return !filteredMapErrors.length ? null : filteredMapErrors;
};

export const getMonthName = (month: number, outputFormat: string = "MMMM") =>
    moment(String(month), "MM").format(outputFormat);

export const getAvailableMonthLabel = (months?: IMonths) => {
    if (!months || (months.startMonth === 1 && months.endMonth === 12)) return "";

    const { startMonth, endMonth } = months;

    if (!endMonth) return `(${getMonthName(startMonth)})`;

    return `(${getMonthName(startMonth)} - ${getMonthName(endMonth)})`;
};

const getMonth = (month: number) => (month.toString().length === 1 ? `0${month}` : month);

const getDaysInMonths = (month: moment.MomentInput, format: string) =>
    moment(month, format).daysInMonth().toString();

export const convertTimePeriodsToApi = (timePeriods: Array<ITimePeriod>) =>
    timePeriods.reduce((result, timePeriod) => {
        const { year, months } = timePeriod;

        if (!months) {
            result.push({
                start_date: `01/01/${year}`,
                end_date: `12/31/${year}`,
            });
            return result;
        }

        if (Array.isArray(months)) {
            months.forEach(month => {
                const daysInMonth = getDaysInMonths(`${year}-${month}`, "YYYY-MM");

                result.push({
                    start_date: `${getMonth(month)}/01/${year}`,
                    end_date: `${getMonth(month)}/${daysInMonth}/${year}`,
                });
            });

            return result;
        }

        const { startMonth, endMonth } = months;
        const _endMonth = endMonth || startMonth;
        const endDateYearMonth = `${year}-${_endMonth}`;
        const daysInMonth = getDaysInMonths(endDateYearMonth, "YYYY-MM");

        result.push({
            start_date: `${getMonth(startMonth)}/01/${year}`,
            end_date: `${getMonth(_endMonth)}/${daysInMonth}/${year}`,
        });

        return result;
    }, [] as Array<IAnalysisDateRange>);

export const getRoadNamesFromRoadIds = (roadTypeIds: TRoadType["id"][]) => {
    const isRampSelected = roadTypeIds.some(roadTypeId => roadTypeId === ROAD_TYPES.RAMP.id);

    const roadNames = roadTypeIds.reduce((result, roadTypeId) => {
        if (roadTypeId === ROAD_TYPES.RAMP.id) return [...result, RAMP_ROAD_NAMES.motorway];

        const roadName = ROAD_TYPES[roadTypeId.toUpperCase() as TRoadTypeKey].name;
        const rampRoadName = RAMP_ROAD_NAMES[roadTypeId as TRampRoadNamesKey];

        if (isRampSelected && rampRoadName) {
            return [...result, roadName, rampRoadName];
        }

        return [...result, roadName];
    }, [] as (TRoadType["name"] | TRampRoadNames)[]);

    return uniq(roadNames);
};

export const convertDateRangesToState = (
    dateRanges: { end_date: string; start_date: string }[],
) => {
    return dateRanges.map(dateRange => {
        return {
            year: moment(dateRange.end_date, "MM/DD/YYYY").year(),
            months: {
                startMonth: moment(dateRange.start_date, "MM/DD/YYYY").month() + 1,
                endMonth: moment(dateRange.end_date, "MM/DD/YYYY").month() + 1,
            },
        };
    }) as Array<ITimePeriod>;
};

export const getRoadIdsFromRoadNames = (roadNames: string[]) => {
    let isRampIdAdded = false;

    return roadNames.reduce((result, roadName) => {
        const roadId = ROAD_TYPES[roadName.toUpperCase() as TRoadTypeKey]?.id;

        if (!roadId) {
            if (!isRampIdAdded) {
                isRampIdAdded = true;
                return [...result, ROAD_TYPES.RAMP.id];
            }

            return result;
        }

        return [...result, roadId];
    }, [] as TRoadType["id"][]);
};

export const configureAnalysisDataToBaseState = ({
    analysisId,
    analysis,
    isCopy,
    isDraft,
    projectFolderName,
    availableAnalyses,
}: {
    isCopy: boolean;
    isDraft: boolean;
    analysisId: number;
    projectFolderName?: string;
    analysis: IAnalysis | TLightningDraftApi;
    availableAnalyses: Array<ITableAnalysisInfo>;
}) => {
    const {
        project_name,
        date_ranges,
        road_types,
        project_region_zones,
        project_folder_id,
        location,
    } = analysis;

    const configurations = {
        analysisName: isCopy
            ? getCopiedAnalysisName(project_name, availableAnalyses).substring(
                  0,
                  ANALYSIS_NAME_MAX_LENGTH,
              )
            : project_name,
        isCopy,
        ...(!isCopy && isDraft ? { draftId: analysisId } : {}),
        projectFolder: project_folder_id
            ? {
                  project_folder_id,
                  project_folder_name: projectFolderName || "",
                  project_folder_color: "",
              }
            : null,
        ...(date_ranges
            ? {
                  timePeriods: convertDateRangesToState(date_ranges),
              }
            : {}),
        ...(road_types ? { roadClassifications: getRoadIdsFromRoadNames(road_types) } : {}),
        ...(project_region_zones
            ? {
                  geographies: project_region_zones.map(region => ({
                      ...region,
                      zone_id: region.zone_id.toString(),
                  })),
              }
            : {}),
    };

    const uiState = {
        screenMode: isCopy ? SCREEN_MODES.COPY.id : SCREEN_MODES.DRAFT.id,
        location: isDraft ? (location as ILocation) : null,
    };

    return { configurations, uiState };
};

export const getSelectedRoadIds = (roadClassifications: Array<TRoadType["id"]>) => {
    const hasOnOffRamps = roadClassifications.indexOf(ROAD_TYPES.RAMP.id) !== -1;
    const hasMotorways = roadClassifications.indexOf(ROAD_TYPES.MOTORWAY.id) !== -1;
    const hasPrimary = roadClassifications.indexOf(ROAD_TYPES.PRIMARY.id) !== -1;

    const roadIds = roadClassifications.reduce((result, roadTypeId) => {
        if (
            roadTypeId === ROAD_TYPES.RAMP.id ||
            roadTypeId === ROAD_TYPES.MOTORWAY.id ||
            roadTypeId === ROAD_TYPES.PRIMARY.id
        ) {
            return result;
        }
        return [
            ...result,
            ...(
                ROAD_TYPES[roadTypeId.toUpperCase() as TRoadTypeKey]
                    .roadIds as unknown as TRoadId[]
            ).filter(roadId => !roadId.includes("_link")),
        ];
    }, [] as TRoadId[]);

    /* Below we have some complex logic on what roadIds to return:

   On/off roads | Motorway  | Primary | Result
         +      |     +     |    +    | motorway, motorway_link, primary, primary_link
         +      |     +     |    -    | motorway, motorway_link
         +      |     -     |    +    | primary, primary_link
         +      |     -     |    -    | motorway_link, primary_link
         -      |     +     |    +    | motorway, primary
         -      |     +     |    -    | motorway
         -      |     -     |    +    | primary
         -      |     -     |    -    | ---

    */

    if (hasOnOffRamps) {
        if (hasMotorways) {
            roadIds.push(...ROAD_TYPES.MOTORWAY.roadIds, RAMP_ROAD_NAMES[ROAD_TYPES.MOTORWAY.id]);
        }
        if (hasPrimary) {
            roadIds.push(...ROAD_TYPES.PRIMARY.roadIds, RAMP_ROAD_NAMES[ROAD_TYPES.PRIMARY.id]);
        }
        if (!hasMotorways && !hasPrimary) {
            roadIds.push(
                RAMP_ROAD_NAMES[ROAD_TYPES.MOTORWAY.id],
                RAMP_ROAD_NAMES[ROAD_TYPES.PRIMARY.id],
            );
        }
    } else {
        if (hasMotorways) {
            roadIds.push(...ROAD_TYPES.MOTORWAY.roadIds);
        }
        if (hasPrimary) {
            roadIds.push(...ROAD_TYPES.PRIMARY.roadIds);
        }
    }

    return roadIds;
};

export const getFilteredSegmentsByRoadClasses = (
    segmentsList: Array<ISegment>,
    roadClassifications: Array<TRoadType["id"]>,
) => {
    const roadIds = getSelectedRoadIds(roadClassifications);

    return segmentsList.filter(_segment => arrayIncludes(roadIds, _segment.highway));
};

export const hasHeaderOffset = ({
    isLoading,
    block,
    className,
    hideOffset,
}: {
    className: string;
    isLoading?: boolean;
    hideOffset: boolean;
    block?: HTMLElement | null;
}) => {
    if (isLoading || hideOffset || !block) return false;

    const list = block.querySelector(`div.${className}`) as HTMLElement;

    return list?.scrollHeight > list?.offsetHeight;
};
