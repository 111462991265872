import { useEffect,useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment/moment";
import { UserInfoBadge } from "@app/bigBang/layout/header/components/userInfoBadge/userInfoBadge";
import {
    getCanAccessSandbox,
    getCurrentUser,
    getIsEmulating,
    getIsOrgHasFeature,
    getIsSandbox,
} from "@app/store/currentUser/currentUser.selector";
import { useAppSelector } from "@app/store/hooks";
import { StlAppHeader, StlButton, StlMenu, StlPopover } from "@common/components";
import type { TMenuControl } from "@common/components/menu/menu";
import { StlPopoverHandlers } from "@common/components/popover/popover";
import { ORG_TYPES } from "@common/constants/orgTypes.constants";
import { ROUTES } from "@common/constants/routes.constants";
import {
    EDIT_STUDY_DAYS_PERIOD,
    ENTERPRISE_STUDY_TYPES,
} from "@common/features/studyModal/common/study.constants";
import { ManageStudyModal } from "@common/features/studyModal/manageStudyModal";
import { RequestQuoteModal } from "@common/features/studyModal/requestQuoteModal";
import { AdminApiService } from "@common/services/server/adminApi.service";
import type { IStudyAPI } from "@common/services/server/studiesApi.types";
import type { IOrganization } from "@common/services/server/userApi.types";
import {
    faBell,
    faChevronDown,
    faGift,
    faInfoCircle,
    faQuestion,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./header.less";

export type TCurrentUser = {
    canChangeOrg: boolean;
    canChangeStudy: boolean;
    isSuper: boolean;
    realUser: { email_addr: string };
    user: {
        email_addr: string;
        study: IStudyAPI;
        org: IOrganization;
    };
};

const STUDY_ACTIONS = {
    CHANGE_STUDY: { id: "changeStudy", label: "Change / Create New Study" },
    REQUEST_QUOTE: { id: "requestQuote", label: "Request Quote" },
    EDIT_STUDY: { id: "editStudy", label: "Edit Study" },
};

const StudyDropdownBadge = <T extends TCurrentUser["user"]>({
    userInfo,
    openRequestQuoteModal,
    openStudyModal,
    canChangeStudy,
    isEnterpriseStudy,
}: {
    userInfo: T;
    openRequestQuoteModal: () => void;
    openStudyModal: () => void;
    canChangeStudy: boolean;
    isEnterpriseStudy: boolean;
}) => {
    const history = useHistory();

    const popover = useRef<StlPopoverHandlers | null>(null);

    const showStudyInfo =
        userInfo.org.org_type === ORG_TYPES.MARKETING_SUBSCRIPTION.id && !userInfo.org.is_eval;

    const studyWarning = useMemo(() => {
        if (!isEnterpriseStudy) {
            return {
                showPopoverByDefault: false,
                Component: (
                    <span className="study-info-text">
                        This account is to be used for marketing purposes and internal research
                        only. Project results should not be used for paid work.
                    </span>
                ),
            };
        }

        const diff = moment().diff(moment(userInfo.study.non_editable_start_date), "days");
        const daysRemain = EDIT_STUDY_DAYS_PERIOD - diff;

        // If Pursuit was created more than 90 days ago, show an alert to convert the Pursuit to Active Project
        if (userInfo.study.study_type === ENTERPRISE_STUDY_TYPES.Pursuit.value && diff > 90) {
            return {
                showPopoverByDefault: true,
                Component: (
                    <span className="study-info-text">
                        If Pursuit is now an Active Project, please convert to Active Project and
                        enter Project Number
                    </span>
                ),
            };
        }
        // User should see an alert if there are less than 14 days left to edit study
        if (daysRemain >= 0 && daysRemain <= 14) {
            const editableField =
                userInfo.study.study_type === ENTERPRISE_STUDY_TYPES.Pursuit.value
                    ? "B&P Number"
                    : "Project Number";
            return {
                showPopoverByDefault: true,
                Component: (
                    <span className="study-info-text">
                        {`${daysRemain} days remaining to edit study information and ${editableField}`}
                    </span>
                ),
            };
        }

        return {
            showPopoverByDefault: false,
            Component: (
                <>
                    <span className="study-info-text">
                        For each new project, please create a new &ldquo;study&ldquo; and enter in
                        the project number & task code (B&P code for pre-sales work).
                    </span>
                    <FontAwesomeIcon
                        id="enterpise-study-info-icon"
                        className="info-icon"
                        icon={faInfoCircle}
                    />
                </>
            ),
        };
    }, [userInfo, isEnterpriseStudy]);

    const StudyMenuControl = (props: TMenuControl) => (
        <StlButton
            className="study-button"
            size="lg"
            endIcon={<FontAwesomeIcon icon={faChevronDown} />}
            {...props}
        >
            Study:&nbsp;
            <span className="study-name">{userInfo.study.study_name}</span>
        </StlButton>
    );

    const options = [
        STUDY_ACTIONS.CHANGE_STUDY,
        isEnterpriseStudy ? STUDY_ACTIONS.EDIT_STUDY : STUDY_ACTIONS.REQUEST_QUOTE,
    ].filter(Boolean);

    const optionsHandlers = {
        [STUDY_ACTIONS.CHANGE_STUDY.id]: () => history.push("/chooseStudy"),
        [STUDY_ACTIONS.REQUEST_QUOTE.id]: () => openRequestQuoteModal(),
        [STUDY_ACTIONS.EDIT_STUDY.id]: () => openStudyModal(),
    };

    const handleSelect = (optionId: keyof typeof optionsHandlers) => {
        optionsHandlers[optionId]();
    };

    // If Pursuit study was created more than 90 days ago or
    // there are less than 14 days left to edit study,
    // warning popover should be shown by default
    useEffect(() => {
        if (studyWarning.showPopoverByDefault && popover.current) {
            popover.current.open();
        }
    }, [studyWarning]);

    return (
        <div className="study-container">
            {canChangeStudy ? (
                <StlMenu
                    id="study-actions-menu"
                    options={options}
                    Control={StudyMenuControl}
                    transformOrigin={{ vertical: -35, horizontal: 0 }}
                    onSelect={handleSelect}
                />
            ) : (
                <span className="read-only">
                    Study:&nbsp;
                    <span className="study-name">{userInfo.study.study_name}</span>
                </span>
            )}
            {showStudyInfo && (
                <StlPopover
                    ref={popover}
                    className="stl-study-info-popover"
                    trigger={studyWarning.showPopoverByDefault ? ["click"] : ["hover", "focus"]}
                    sourceControl={
                        <span>
                            <FontAwesomeIcon icon={faBell} />
                        </span>
                    }
                    contentClickable
                    allowMouseLeaveOnSourceControl={false}
                >
                    {studyWarning.Component}
                </StlPopover>
            )}
        </div>
    );
};

const EmulationBadge = <T extends TCurrentUser>({
    currentUser,
    endEmulation,
}: {
    currentUser: T;
    endEmulation: () => void;
}) => (
    <div className="emulation-badge">
        <div className="emulation-badge-user-name">
            {"Logged in as: "}
            <span className="emulation-badge-user-email">{currentUser.realUser.email_addr}</span>
        </div>
        <StlButton onClick={endEmulation} className="emulation-badge-link">
            stop emulation
        </StlButton>
    </div>
);

const endEmulation = () => {
    AdminApiService.endEmulation();
};

export const Header = () => {
    const [showRequestQuoteModal, setShowRequestQuoteModal] = useState(false);
    const [showStudyModal, setShowStudyModal] = useState(false);

    const currentUser = useAppSelector(getCurrentUser);
    const isEmulating = useAppSelector(getIsEmulating);
    const isSandbox = useAppSelector(getIsSandbox);
    const canAccessSandbox = useAppSelector(getCanAccessSandbox);
    const isEnterpriseStudy = useAppSelector(state =>
        getIsOrgHasFeature(state, "enterprise_study_workflow"),
    );

    const { user: userInfo, canChangeOrg } = currentUser;

    const history = useHistory();

    const mountRequestQuoteModal = () => (
        <RequestQuoteModal
            study={userInfo.study}
            show={showRequestQuoteModal}
            onHide={() => setShowRequestQuoteModal(false)}
        />
    );

    const mountStudyModal = () => (
        <ManageStudyModal
            study={userInfo.study}
            show={showStudyModal}
            onHide={() => setShowStudyModal(false)}
        />
    );

    const logoContent = userInfo.study && (
        <StudyDropdownBadge
            userInfo={userInfo}
            openRequestQuoteModal={() => setShowRequestQuoteModal(true)}
            openStudyModal={() => setShowStudyModal(true)}
            canChangeStudy={currentUser.canChangeStudy}
            isEnterpriseStudy={isEnterpriseStudy}
        />
    );

    const skipToMainContent = () => {
        const title = document.querySelector("h1");
        if (title) {
            title.focus();
        }
    };

    return (
        <StlAppHeader
            logoContent={logoContent}
            logoUrl="/img/main-app-logo-insight.png"
            onClickLogo={() => history.push(ROUTES.DEFAULT)}
        >
            <StlButton onClick={() => skipToMainContent()} className="skip-main" variant="link">
                Skip to main content
            </StlButton>
            <div className="user-info-container">
                <div className="left-bottom-banner">
                    <StlButton
                        className="header-btn"
                        id="help-actions-menuControl"
                        startIcon={<FontAwesomeIcon icon={faQuestion} />}
                        aria-hidden="true"
                        tabIndex={-1}
                        size="sm"
                    />
                    {!isSandbox && !currentUser.isSuper && (
                        <StlButton
                            className="header-btn"
                            id="whats-new-button"
                            startIcon={<FontAwesomeIcon icon={faGift} />}
                            size="sm"
                        />
                    )}
                    <UserInfoBadge userInfo={userInfo} canChangeOrg={canChangeOrg} />
                </div>
                <div className="right-top-banner">
                    {canAccessSandbox && (
                        <span
                            id={isSandbox ? "exit-sandbox" : "enter-sandbox"}
                            aria-hidden="true"
                        />
                    )}
                    {!isSandbox && currentUser.isSuper && (
                        <div className="admin-banner">
                            <h4>acting as superuser</h4>
                        </div>
                    )}
                    {isEmulating && (
                        <EmulationBadge currentUser={currentUser} endEmulation={endEmulation} />
                    )}
                </div>
            </div>
            {mountRequestQuoteModal()}
            {mountStudyModal()}
        </StlAppHeader>
    );
};
