import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { USER_ROLES } from "@app/store/currentUser/currentUser.constants";
import { StlButton, StlPopover } from "@common/components";
import { PLATFORM_ACCESS_TYPES } from "@common/constants/orgTypes.constants";
import type { IUserAPI } from "@common/services/server/userApi.types";
import { faCaretDown, faCheck, faUserAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./userInfoBadge.less";

const FREE_TRIAL_ACCOUNT = "Free Trial";
const ANCHOR_ORIGIN = { vertical: "bottom", horizontal: "right" } as const;
const TRANSFORM_ORIGIN = { vertical: "top", horizontal: "right" } as const;

const ACTIONS = {
    CHANGE_ORG: "changeOrg",
    LOG_OUT: "logOut",
};

export const UserInfoBadge = <T extends IUserAPI>({
    userInfo,
    canChangeOrg,
}: {
    userInfo: T;
    canChangeOrg: boolean;
}) => {
    const [showUserBadge, setShowUserBadge] = useState(true);

    const { org, roles } = userInfo;

    const history = useHistory();

    const handleOpen = () => setShowUserBadge(true);
    const handleClose = () => setShowUserBadge(false);

    const userLabel = useMemo(() => {
        return (
            <StlButton
                startIcon={<FontAwesomeIcon icon={faUserAlt} />}
                endIcon={<FontAwesomeIcon icon={faCaretDown} />}
                className={classNames(
                    "stl-user-info-badge",
                    showUserBadge && "account-action-pressed",
                )}
                onClick={handleOpen}
            >
                {userInfo.email_addr}
            </StlButton>
        );
    }, [userInfo.email_addr, showUserBadge]);

    const accountTitle = useMemo(() => {
        const isUserRoleLiteOnly = roles.length === 1 && roles[0] === USER_ROLES.INSIGHT_GO;
        const isOrgLiteOnly =
            org.product_access_types?.length === 1 &&
            org.product_access_types.includes(PLATFORM_ACCESS_TYPES.LITE.value);

        const isLiteOnly = isOrgLiteOnly || isUserRoleLiteOnly;

        return isLiteOnly ? FREE_TRIAL_ACCOUNT : org.org_name;
    }, [org.product_access_types, org.org_name, roles]);

    const actions = {
        [ACTIONS.CHANGE_ORG]: () => history.push("/chooseOrg"),
        [ACTIONS.LOG_OUT]: () => history.push("/logout"),
    };

    return (
        <StlPopover
            id="user-info-dropdown"
            sourceControl={userLabel}
            className="stl-user-info-popover"
            anchorOrigin={ANCHOR_ORIGIN}
            transformOrigin={TRANSFORM_ORIGIN}
            onClose={handleClose}
            contentClickable
        >
            <div className="user-section">
                <div className="user-section-item">
                    <h2 className="user-section-title user-section-title--bold">User</h2>
                    {userInfo.email_addr}
                </div>

                <div className="user-section-item">
                    <h2 className="user-section-title">Accounts</h2>
                    <div className="account-name">
                        <FontAwesomeIcon icon={faCheck} className="checkmark" />
                        {accountTitle}
                    </div>
                </div>
                {canChangeOrg && (
                    <StlButton
                        variant="primary"
                        className="change-account-button"
                        onClick={actions[ACTIONS.CHANGE_ORG]}
                    >
                        Change Account
                    </StlButton>
                )}
            </div>
            <div className="actions-section">
                <StlButton className="logout-button" onClick={actions[ACTIONS.LOG_OUT]}>
                    <FormattedMessage id="app.signOut" defaultMessage="Sign Out" />
                </StlButton>
            </div>
        </StlPopover>
    );
};
