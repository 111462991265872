import { CancelTokenSource } from "axios";
import hash from "object-hash";
import { MetricsCacheService } from "@app/viz3/base/services/metricsCacheService";
import { HttpService, IRequestConfig } from "@common/services/server/http.service";
import {
    IAnalysisGeometriesData,
    IDistributionLayerGroupData,
    IDistributionLayerGroupWithFilterHashData,
    IGetAnalysisGeometriesResponse,
    IGetAnalysisZonesLayerGroupData,
    IGetAnalysisZonesLayerGroupResponse,
    IGetBoundingBoxResponse,
    IGetCorridorGeoJsonRequestBody,
    IGetCorridorGeoJsonResponse,
    IGetDistributionLayerGroupResponse,
    IGetDotsDensityLayerGroupData,
    IGetDotsDensityLayerGroupResponse,
    IGetOsmCorridorLayerData,
    IGetOsmCorridorLayerResponse,
    IGetOsmLayerGroupData,
    IGetOsmLayerGroupResponse,
    IGetStagedZonesLayerGroupData,
    IGetStagedZonesLayerGroupResponse,
    IGetZoneKindLayerGroupData,
    IGetZoneKindLayerGroupResponse,
    IGetZonesLayerGroupData,
    IVizLayerGroupConfig,
    TGetBoundingBoxData,
} from "@common/services/server/layerGroupApi.types";

export const LayerGroupApiService = {
    cancelTokensDataMap: new Map<
        string,
        { cacheId?: string; cancelTokenSource: CancelTokenSource }
    >(),

    cancelRequests(requestCacheId?: string): void {
        this.cancelTokensDataMap.forEach((tokenData, key) => {
            if (!tokenData.cacheId || tokenData.cacheId === requestCacheId) {
                tokenData.cancelTokenSource.cancel();
                this.cancelTokensDataMap.delete(key);
            }
        });
    },

    getDistributionLayerGroup({
        analysisId,
        filters,
        zoomConfig = {},
        layerGroupConfig = {},
    }: IDistributionLayerGroupData) {
        const params = {
            ...filters,
            ...zoomConfig,
        };

        return HttpService.post<IGetDistributionLayerGroupResponse>(
            `/layergroup/distribution/${analysisId}`,
            params,
            layerGroupConfig,
        );
    },
    getDistributionLayerGroupWithCache({
        analysisId,
        filters,
        layerGroupConfig = {},
        zoomConfig = {},
        cacheServiceConfig,
    }: IDistributionLayerGroupData) {
        this.cancelRequests(cacheServiceConfig?.cacheId);

        return MetricsCacheService.cacheMetrics(analysisId, filters, cacheServiceConfig).then(
            () => {
                const source = HttpService.CancelToken.source();

                const params = {
                    ...filters,
                    ...zoomConfig,
                };

                const filtersHash = hash({ analysisId, filters }) as string;

                this.cancelTokensDataMap.set(filtersHash, {
                    cacheId: cacheServiceConfig?.cacheId,
                    cancelTokenSource: source,
                });

                return HttpService.post(`/layergroup/distribution/${analysisId}`, params, {
                    ...layerGroupConfig,
                    cancelToken: source.token,
                });
            },
        );
    },
    getDistributionLayerGroupWithFilterHash({
        analysisId,
        metricsCacheFilters,
        layerGroupFilters,
        layerGroupConfig = {},
        zoomConfig = {},
        cacheServiceConfig,
    }: IDistributionLayerGroupWithFilterHashData) {
        this.cancelRequests(cacheServiceConfig?.cacheId);

        return MetricsCacheService.cacheMetrics(
            analysisId,
            metricsCacheFilters,
            cacheServiceConfig,
        ).then(() => {
            const source = HttpService.CancelToken.source();

            //@ts-ignore Property 'filter_hash' does not exist on type 'lastCacheResponse'.
            const { filter_hash } = MetricsCacheService.lastCacheResponse;

            const params = {
                filter_hash,
                ...layerGroupFilters,
                ...zoomConfig,
            };

            this.cancelTokensDataMap.set(filter_hash, {
                cacheId: cacheServiceConfig?.cacheId,
                cancelTokenSource: source,
            });

            return HttpService.post<IGetDistributionLayerGroupResponse>(
                `/layergroup/distribution/${analysisId}`,
                params,
                {
                    ...layerGroupConfig,
                    cancelToken: source.token,
                },
            );
        });
    },
    getAnalysisGeometries(
        analysisId: number,
        body: IAnalysisGeometriesData | {} = {},
        config: IRequestConfig<IVizLayerGroupConfig> = {},
    ) {
        return HttpService.post<IGetAnalysisGeometriesResponse>(
            `/layergroup/geometries/${analysisId}`,
            body,
            config,
        );
    },
    getZoneKindLayerGroup(
        body: IGetZoneKindLayerGroupData,
        config: IRequestConfig<IVizLayerGroupConfig> = {},
    ) {
        return HttpService.post<IGetZoneKindLayerGroupResponse>(
            "/layergroup/zone/zone_kind",
            body,
            config,
        ).catch(
            HttpService.showIfError(
                "Unknown error occurred while receiving zone kind layer group.",
            ),
        );
    },
    getZonesLayerGroup(
        body: IGetZonesLayerGroupData,
        config: IRequestConfig<IVizLayerGroupConfig> = {},
    ) {
        return HttpService.post<IGetAnalysisZonesLayerGroupResponse>(
            "/layergroup/zone",
            body,
            config,
        ).catch(
            HttpService.showIfError("Unknown error occurred while receiving zones layer group."),
        );
    },
    getStagedZonesLayerGroup(
        body: IGetStagedZonesLayerGroupData,
        config: IVizLayerGroupConfig = {},
    ) {
        return HttpService.post<IGetStagedZonesLayerGroupResponse>(
            "/layergroup/staged_zone",
            body,
            config,
        ).catch(
            HttpService.showIfError("Unknown error occurred while receiving zones layer group."),
        );
    },
    getAnalysisZonesLayerGroup(
        analysisId: number | string,
        body: IGetAnalysisZonesLayerGroupData,
        config: IVizLayerGroupConfig = {},
    ) {
        return HttpService.post<IGetAnalysisZonesLayerGroupResponse>(
            `/layergroup/zone/${analysisId}`,
            body,
            config,
        ).catch(
            HttpService.showIfError("Unknown error occurred while receiving zones layer group."),
        );
    },
    getBoundingBox(data: TGetBoundingBoxData, config: IRequestConfig<IVizLayerGroupConfig> = {}) {
        return HttpService.post<IGetBoundingBoxResponse>("/zone/bounding_box", data, config);
    },
    getOsmLayerGroup(
        body: IGetOsmLayerGroupData,
        config: IRequestConfig<IVizLayerGroupConfig> = {},
    ) {
        return HttpService.post<IGetOsmLayerGroupResponse>(
            "/layergroup/osm_layer",
            body,
            config,
        ).catch(
            HttpService.showIfError("Unknown error occurred while receiving osm layer group."),
        );
    },

    getDotsDensityLayerGroup(projectId: number, body: IGetDotsDensityLayerGroupData) {
        return HttpService.post<IGetDotsDensityLayerGroupResponse>(
            `/layergroup/dots/${projectId}`,
            body,
        );
    },
    getCorridorGeoJson(projectId: number, body: IGetCorridorGeoJsonRequestBody) {
        return HttpService.post<IGetCorridorGeoJsonResponse>(
            `/geojsonlayer/distribution/${projectId}`,
            body,
        );
    },
    getOsmCorridorLayer(body: IGetOsmCorridorLayerData) {
        return HttpService.post<IGetOsmCorridorLayerResponse>(
            "/layergroup/osm_corridor_layer",
            body,
        );
    },
};
