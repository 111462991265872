import { FormattedMessage } from "react-intl";
import { StlButton, StlConfirmationPopover } from "@common/components";
import { formatBooleanYesNo } from "@common/formatters/formatBooleanYesNo";
import FormatUnlimited from "@common/formatters/formatUnlimited";
import { OrgApiService } from "@common/services/server/orgApi.service";
import type { IOrgBalanceAPI } from "@common/services/server/orgApi.types";
import { StlAccountListItem } from "./accountListItem";

type TProps = {
    orgBalance: IOrgBalanceAPI;
    zoneProjectRemain?: number;
    showExportUniqueZoneInfo: boolean;
};

export const AnalysisInformation = ({
    orgBalance,
    zoneProjectRemain,
    showExportUniqueZoneInfo,
}: TProps) => {
    const requestZoneUpgrade = () => {
        OrgApiService.requestZoneUpgrade({
            zones_shortfall: Math.abs(zoneProjectRemain || 0),
        });
    };
    return (
        <>
            <StlAccountListItem title="Analysis Quota:">
                {orgBalance.project_quota === null || orgBalance.project_quota === undefined
                    ? "Unlimited"
                    : `${orgBalance.project_count} Used / ${orgBalance.project_remain} Available`}
            </StlAccountListItem>
            <StlAccountListItem title="Zones Per Analysis:">
                <FormatUnlimited value={orgBalance.zones_per_project} />
            </StlAccountListItem>
            <StlAccountListItem title="Unique Zones Quota:">
                {orgBalance.unique_zone_quota === null ||
                orgBalance.unique_zone_quota === undefined
                    ? "Unlimited"
                    : `${orgBalance.unique_zone_count} Used / ${orgBalance.unique_zone_remain} Available`}
            </StlAccountListItem>
            {orgBalance.unique_zone_quota !== null && orgBalance.unique_zone_quota !== undefined && (
                <li>
                    <div className="card-item-label-link">
                        <StlConfirmationPopover
                            onConfirm={() => requestZoneUpgrade()}
                            buttonVariant="link"
                            buttonText={
                                <FormattedMessage
                                    id="app.requestMoreZones"
                                    defaultMessage="Request More Zones"
                                />
                            }
                            confirmationText={
                                <FormattedMessage
                                    id="app.confirmUpgradeRequest"
                                    defaultMessage="Upgrade request will be submitted and your StreetLight Data representative will get in touch with you. Do you want to proceed?"
                                    description="Text in confirmation popover for request unique zones upgrade"
                                />
                            }
                        />
                    </div>
                </li>
            )}
            {showExportUniqueZoneInfo && (
                <li>
                    <div className="card-item-label-link">
                        <StlButton variant="link" href="/server/export/zone/unique_zone_info">
                            Export Unique Zone Information
                        </StlButton>
                    </div>
                </li>
            )}
            <StlAccountListItem title="Region Limit:">
                {formatBooleanYesNo(orgBalance.regional_subscription)}
            </StlAccountListItem>
        </>
    );
};
