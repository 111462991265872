import { TProductType } from "@common/services/server/userApi.types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faRoad,
    faRoadBarrier,
    faTrafficCone,
    faTrafficLight,
} from "@fortawesome/pro-solid-svg-icons";

export const EXIT_SANDBOX_PATH = "/exit-sandbox";

export const AUTH_TYPES = {
    PASSWORD: "Password",
    MICROSOFT: "Microsoft",
} as const;

export const PRODUCT_DETAILS: Record<
    TProductType,
    { description: string; image: IconProp; route: string }
> = {
    insight_full: {
        description:
            "StreetLight InSight is a self-service, online, on-demand platform offering users access to comprehensive mobility metrics anytime and anywhere.",
        image: faTrafficLight,
        route: "/",
    },
    safety_prioritize: {
        description:
            "Safety Prioritize is a custom-built application using Esri dashboards with distinct modules providing a complete solution to an agency’s safety planning process. It solves users' need to develop Highway Safety Improvement plans, Vulnerable Road User plans, apply for grant funding, and tackle safety with the Highway Safety Manual’s process.",
        image: faTrafficCone,
        route: "/safety-prioritize",
    },
    traffic_monitor: {
        description:
            "Operations (Construction and Traffic Monitor) is a platform which helps Transportation Professionals with high quality, low latency metrics for planning + reporting. The result is reduced costs and improved trust with the end-client.",
        image: faRoadBarrier,
        route: "https://traffic_monitor.streetlightdata.com/",
    },
    construction: {
        description:
            "Operations (Construction and Traffic Monitor) is a platform which helps Transportation Professionals with high quality, low latency metrics for planning + reporting. The result is reduced costs and improved trust with the end-client.",
        image: faRoad,
        route: "https://construction.streetlightdata.com/",
    },
};
