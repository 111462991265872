import {
    CREATE_ANALYSIS_TYPES,
    SCREEN_MODES,
    TABS,
    TCreateAnalysisType,
    TScreenMode,
    TTab,
    TZoneMode,
    ZONES_MODES,
} from "@app/analysis/state/analysisConfiguration.constants";
import type {
    ISizeReviewData,
    IUniqueZoneData,
} from "@app/analysis/state/analysisConfiguration.types";
import type { ISelectedProjectFolder } from "@common/services/server/projectFolderApi.types";

export interface IRunAnalysis {
    status?: string;
    isDraft?: boolean;
    submitted?: boolean;
    error?: string | string[];
    zone_project_remain?: number;
    uniqueZoneData?: IUniqueZoneData | null;
    sizeReviewData?: ISizeReviewData | null;
}

export interface IValidation {
    isInvalid: boolean;
}

export interface IGeneralUIState {
    activeTab: TTab["id"];
    screenMode: TScreenMode["id"];
    zonesMode: TZoneMode;
    isLoading: boolean;
    readOnly?: boolean;
    showPreviewAnalysis: boolean;
    isAnalysisTypeChanged: boolean;
    is15MinuteBinsModeActive: boolean;
    isShowAllAnalysesAllowed: boolean;
    isZonesTableCollapsed: boolean;
    isViewFromDashboard: boolean;
}

export interface IGeneralState {
    analysisStatus: string;
    analysisId: string | null;
    analysisVersionId: number | null;
    analysisTypeCode: TCreateAnalysisType["code"];
    validation: IValidation;
    uiStates: IGeneralUIState;
    runAnalysis: IRunAnalysis;
    projectFolder: ISelectedProjectFolder | null;
}

export const GENERAL_INITIAL_STATE = {
    analysisId: null,
    analysisVersionId: null,
    analysisStatus: "",
    analysisTypeCode: CREATE_ANALYSIS_TYPES.OD.code,
    uiStates: {
        isLoading: false,
        screenMode: SCREEN_MODES.DEFAULT.id,
        zonesMode: ZONES_MODES.CHOOSE_ZONES,
        activeTab: TABS.BASICS.id,
        is15MinuteBinsModeActive: false,
        showPreviewAnalysis: false,
        isAnalysisTypeChanged: false,
        isShowAllAnalysesAllowed: false,
        isZonesTableCollapsed: false,
        isViewFromDashboard: false,
    },
    validation: {
        isInvalid: true,
    },
    runAnalysis: {} as IRunAnalysis,
    projectFolder: null,
} as IGeneralState;
